import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { baseTextField, inputField, inputLabel, invalidInput } from '../../../jss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    evlHeader: {
      maxWidth: theme.spacing(20),
      margin: `0 auto`,

      '& $img': {
        width: '100%',
      },
    },
    linkUnderline: {
      textUnderlineOffset: '3px',
    },
    enterYourOtp: {
      fontWeight: 500,
      fontSize: '20px',
      color: '#000000',
      lineHeight: '24px',
      marginBottom: '12px',
      marginTop: '8px',
    },
    footerActions: {
      marginTop: '28px',
    },
    '@media (min-height: 600px)': {
      footerActions: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        margin: '20px',
      },
    },
    phoneFooterActions: {
      left: 0,
      right: 0,
      bottom: 0,
      margin: '20px',
      position: 'absolute',
    },
    cancelButton: {
      marginTop: '12px',
      backgroundColor: '#E7EBED',
      color: '#062734',

      '&:hover': {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #E7EBED',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      },
    },
    successUpdateLabel: {
      fontWeight: 500,
      fontSize: '20px',
      fontStyle: 'normal',
      lineHeight: '24px',
    },

    successIcon: {
      width: '100%',
      height: '60px',
      fill: 'none',
      stroke: '#062734',
      strokeWidth: 2,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    evlBrand: {
      width: '100%',
      margin: `${theme.spacing(0)} auto`,
      maxWidth: '100%',
    },
    titleContainer: {
      marginBottom: '0',
    },
    panelTitle: {
      textAlign: 'center',
    },
    textField: {
      ...baseTextField(theme).default,
    },
    textLabel: inputLabel(theme).default,
    textLabelFocused: inputLabel(theme).default,
    textInput: inputField(theme).default,
    textFields: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flexStart',
      width: '100%',
    },
    textFieldIcon: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.primary.contrastText,
      width: theme.spacing(5.2),
      height: theme.spacing(5.2),
      border: `0px`,

      '&:hover': {
        borderColor: theme.palette.secondary.light,
      },
    },
    submitButtonPanel: {
      display: 'flex',
      backgroundColor: theme.palette.secondary.main,
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: `${theme.spacing(5)}px 0px 0px 0px`,
    },
    submitButton: {},
    loadingButton: {},
    icon: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    badUsername: {
      ...invalidInput(theme).default,
      paddingLeft: 16,
      textAlign: 'left',
    },
    buttonRoot: {
      margin: 0,
      backgroundColor: theme.palette.secondary.main,
      padding: '22px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    buttonLabel: {},
    loginOptionContainer: {
      backgroundColor: theme.palette.secondary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      marginTop: theme.spacing(6),
      fontSize: '1.18rem',
      width: '100%',

      '& p': {
        margin: 0,
      },

      '& a': {
        color: 'white',
        fontWeight: 500,
        textDecoration: 'underline',
        textTransform: 'uppercase',
      },
    },
    disabledButton: {
      color: 'white !important',
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.5,
    },
    linkButton: {
      padding: 0,
      backgroundColor: theme.palette.secondary.main,
    },
    forgotPasswordExplainerText: {
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
    },
    snackbar: {
      '& > div': {
        width: 'calc(100% - 17px)',
        maxWidth: '540px',
        bottom: 'auto',

        '& > div': {
          width: '100%',
        },
      },
    },
    subtitle: {
      paddingBottom: '22px',
    },
    resendText: {
      color: '#0075B7',
      fontSize: '14px',
      fontWeight: 600,
      borderBottom: '1px solid #0075B7',
      marginLeft: '5px',
    },
  }),
);

export default useStyles;
