import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { baseTextField, inputField, authContainerSetup } from '../../../jss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    promptIcon: {
      marginRight: '5px',
    },
    invalidText: {
      color: '#C54F50',
      fontSize: '12px',
      fontFamily: 'Gotham Book',
    },
    validText: {
      color: '#65B365',
      fontSize: '12px',
      fontFamily: 'Gotham Book',
    },
    prompt: {
      color: '#616060',
      fontSize: '12px',
      fontFamily: 'Gotham Book',
      marginTop: '3px',
    },
    validations: {
      marginTop: '3px',
    },
    authContainer: {
      ...authContainerSetup(theme).default,
      position: 'relative',
      paddingBottom: '35px',
      minHeight: 'calc(100vh - 114px)',
      alignItems: 'start',
    },
    evlHeader: {
      maxWidth: theme.spacing(20),
      margin: `0 auto`,

      '& $img': {
        width: '100%',
      },
    },
    evlBrand: {
      width: '100%',
      margin: `${theme.spacing(0)} auto`,
      maxWidth: '100%',
    },
    description: {
      color: theme.palette.primary.main,
      marginTop: `${theme.spacing(1.4)}px !important`,
    },
    textField: baseTextField(theme).default,
    textInput: inputField(theme).default,
    linkButton: { padding: 0 },
    disabledButton: {},
    tabButton: {
      minWidth: 'unset',
    },
    tabButtonSelected: {},
    tabButtonLabel: {
      width: 'unset',
    },
    registerButtonPanel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: `${theme.spacing(5)}px 0px 0px 0px`,
    },
    tokenInputField: {
      margin: '4px',
      width: '30px',
      backgroundColor: theme.palette.background.default,

      '& input': {
        textAlign: 'center',
        color: theme.palette.primary.light,
        fontSize: '1.4rem',
      },
    },
    logoContainer: {
      width: '60%',
      minWidth: 160,
      minHeight: 88,
    },
    fieldSectionContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    nameFieldContainer: {
      width: '100%',
    },
    buttonsContainer: {
      marginTop: theme.spacing(3),
      width: '100%',

      '& button:first-child': {
        marginRight: '12px',

        [theme.breakpoints.down('xs')]: {
          marginRight: '0',
        },
      },
    },
    backButton: {
      width: '30%',
      maxWidth: '160px',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: theme.spacing(1.8),
        maxWidth: 'unset',
      },
    },
    btn: {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    backButtonDisabled: {
      width: '30%',
      maxWidth: '160px',
    },
    nextButton: {
      width: '30%',
      maxWidth: '160px',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: theme.spacing(1),
        maxWidth: 'unset',
      },
    },
    termsButtonsContainer: {
      '& button:last-child': {
        marginBottom: theme.spacing(4),
      },
    },
    termsButton: {
      marginBottom: theme.spacing(1.5),
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.primary.light,
      fontSize: theme.typography.fontSize,
      backgroundColor: '#212121',
      width: '100%',
      padding: '10px 0',

      '&:hover': {
        backgroundColor: '#212121',
      },
    },
    termsButtonIcon: {
      width: '20px',
      marginLeft: '10px',
    },
    newUsernameField: {
      backgroundColor: theme.palette.background.default,
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    termsText: {
      textAlign: 'left',
      marginTop: 16,
    },
    birthdayField: {
      width: '100%',
      textAlign: 'left',
      color: theme.palette.primary.main,
      marginBottom: '8px',
    },
    birthdayInputFields: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '7px',
    },
    birthdayDateInput: {
      width: '19.4%',
    },
    birthdayMonthInput: {
      width: '50%',
    },
    birthdayYearInput: {
      width: '24%',
    },
    errorMessage: {
      fontFamily: 'Lato, Arial',
      color: '#FF3D3D',
      bottom: '-5px',
      position: 'relative',
      fontSize: '14.5px',
      textTransform: 'initial',
    },
    phoneNumber: {
      '& > p': {
        marginTop: 0,
      },
    },
    progress: {
      marginTop: '-8px',
      padding: '0px 12px',
    },
    header: {
      fontFamily: 'Lato',
      textAlign: 'left',
      marginTop: '17px',
      marginBottom: '2px',
    },
    subtext: {
      textAlign: 'left',
      lineHeight: '17px',
      marginBottom: '15px',
    },
    fieldSpacing: {
      '& > .MuiGrid-item': {
        padding: '8px 0px',
      },
    },
    resendText: {
      color: '#0075B7',
      fontSize: '13.7px',
      fontWeight: 600,
      borderBottom: '1px solid #0075B7',
      marginLeft: '5px',
    },
    forgotWrapper: {
      marginTop: '5px',
      marginBottom: '24px',
    },
    policies: {
      marginTop: '16px',

      '& > a': {
        color: '#636363',
        borderBottom: '1px solid',

        '&:active': {
          color: '#636363',
        },
      },
    },
    snackbar: {
      '& > div': {
        width: 'calc(100% - 17px)',
        maxWidth: '540px',
        bottom: 'auto',

        '& > div': {
          width: '100%',
        },
      },
    },
    codeLoaderWrapper: {
      marginTop: '10px',
      marginBottom: '38px',

      '& > div': {
        height: '64px',
        width: '64px',
      },
    },
    startLoaderWrapper: {
      marginTop: '45px',
      marginBottom: '83px',

      '& > div': {
        height: '64px',
        width: '64px',
      },
    },
    birthdayLoaderWrapper: {
      marginTop: '20px',
      marginBottom: '16px',

      '& > div': {
        height: '64px',
        width: '64px',
      },
    },
    cityLoaderWrapper: {
      marginTop: '-10px',
      marginBottom: '10px',

      '& > div': {
        height: '64px',
        width: '64px',
      },
    },
    login: {
      color: '#0075B7',
      fontSize: '14px',
      fontWeight: 600,
      borderBottom: '1px solid #0075B7',
      marginLeft: '8px',
    },
    footer: {
      position: 'absolute',
      bottom: '-9px',
      width: '100%',
      textAlign: 'center',
    },
    footerText: {
      display: 'inline',
    },
    errorText: {
      color: '#B00020',
      margin: 0,
      fontFamily: 'Lato, sans-serif',
      fontSize: '12px',
    },
    dobLabel: {
      fontFamily: 'Gotham Book',
      fontSize: '15px',
    },
    loginContainer: {
      backgroundColor: theme.palette.primary.main,
      minHeight: '476px',
      height: '100%',
      width: '100%',
      position: 'absolute',
    },
  }),
);

export default useStyles;
