import React, { ReactNode, Dispatch, SetStateAction } from 'react';
import { VerificationToken } from '../../../components';
import Loader from '../../../components/loader';
import { Status } from '../../../types';
import { useStyles } from '.';
import { Typography } from '@material-ui/core';
import { useFormatMessage } from 'evl-ui-lib';
import { EvlLink } from 'evl-ui-components';

interface CodePanelProps {
  codeValues: String[];
  setCodeValues: Dispatch<SetStateAction<String[]>>;
  handleResendClick: () => void;
  resendCodeStatus: Status;
  children?: ReactNode;
  existingNumber?: string;
  onChangePhoneNumber?: () => void;
  fullNumber?: string;
  email?: boolean;
  error?: boolean;
  hideText?: boolean;
  settings?: boolean;
}

const CodePanel = ({
  codeValues,
  setCodeValues,
  handleResendClick,
  resendCodeStatus,
  children,
  existingNumber,
  fullNumber,
  email,
  error,
  hideText,
  settings,
}: CodePanelProps) => {
  const classes = useStyles();
  const translate = useFormatMessage();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <VerificationToken
        codeValues={codeValues}
        setCodeValues={setCodeValues}
        inputType="numbers"
        fullNumber={fullNumber || existingNumber}
        error={error}
        hideText={hideText}
        hideCaption={hideText}
        settings={settings}
      />
      {children}
      {resendCodeStatus !== Status.inProgress && (
        <>
          <Typography variant="body1" className={classes.subtitle}>
            {translate('codePanel.noCodeReceivedQuestion')}
          </Typography>
          <EvlLink onClick={handleResendClick} className={classes.resendText}>
            {email
              ? translate('codePanel.noCodeReceivedButtonLabel.email')
              : translate('codePanel.noCodeReceivedButtonLabel')}
          </EvlLink>
        </>
      )}
      {resendCodeStatus === Status.inProgress && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
    </React.Fragment>
  );
};

export default CodePanel;
