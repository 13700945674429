// import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationContainer: {
      textAlign: 'center',
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      borderRadius: '6px',
      position: 'relative',
      maxWidth: '500px',
      margin: theme.spacing(2),

      '& p': {
        color: theme.palette.primary.main,
      },
    },
  }),
);

interface SuccessNotificationProps {
  message: string;
  handleCloseClick: () => void;
}

const InfoBanner = ({ message, handleCloseClick }: SuccessNotificationProps) => {
  const classes = useStyles();

  return (
    <div onClick={handleCloseClick} className={classes.notificationContainer}>
      <Typography> {message} </Typography>
    </div>
  );
};

export default InfoBanner;
