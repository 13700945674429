import { createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    evlFooterBox: {
      position: 'absolute',
      bottom: theme.spacing(2),
      left: theme.spacing(0),
      right: theme.spacing(0),
      margin: `${theme.spacing(0)} auto`,
      width: '90%',
    },
    evlBrand: {
      width: '100%',
      margin: `${theme.spacing(0)} auto`,
      maxWidth: theme.spacing(10),
      marginBottom: theme.spacing(0.6),
    },
    languageSelectorFooter: {
      width: '100%',
      maxWidth: '600px',
      borderBottom: '1px solid #dedede',
      margin: `${theme.spacing(0.5)}px auto`,
      color: '#FFFFFF',
      fontSize: `${theme.spacing(1.2)}px`,
    },
    languageSelectorContainer: {
      display: 'flex',
    },
    languageSelector: {
      paddingRight: `${theme.spacing(1)}px`,
      textTransform: 'capitalize',
      fontWeight: 'normal'
    }
  }),
);

export default useStyles;
