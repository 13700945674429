import React from 'react';
import { useRouter } from 'next/router';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { Button, EvlTypography } from 'evl-ui-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      padding: theme.spacing(0.5),
      border: 'none',
      backgroundColor: 'transparent',
      marginRight: theme.spacing(2),

      '& img': {
        width: '12px',
        marginRight: theme.spacing(1.5),
      },
    },
    backText: {
      textTransform: 'capitalize',
      position: 'relative',
      top: '-1px',
    },
  }),
);

interface BackBarProps {
  id?: string;
  backText?: string;
}

const BackBar = ({ backText, ...props }: BackBarProps) => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <React.Fragment>
      <Button
        id={props.id || 'button-backbar-back'}
        onClick={() => router.back()}
        classes={{
          root: classes.backButton,
        }}
      >
        <ChevronLeft color="primary" />
        {backText ? (
          <EvlTypography color="primary" className={classes.backText}>
            {backText}
          </EvlTypography>
        ) : (
          undefined
        )}
      </Button>
    </React.Fragment>
  );
};

export default BackBar;
