import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkButton: {
      padding: 0,
      cursor: 'pointer',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    disabledButton: {},
    errorMessageContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    titleContainer: {
      width: '100%',
    },
    description: {
      color: theme.palette.primary.main,
      marginTop: `${theme.spacing(1.4)}px !important`,
    },
    wrongNumberContainer: {
      width: '100%',
      position: 'absolute',
      bottom: '72px',
      left: 0,
      right: 0,
    },
    loader: {
      justifyContent: 'center',
      display: 'flex',
    },
    resend: {
      padding: '5px',
      textTransform: 'none',
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: 600,
      color: '#375878',
      textDecorationLine: 'underline',

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    subtitle: {
      fontFamily: 'Lato',
      fontSize: '14px',
      color: '#636363',
      display: 'inline-block',
    },
    resendText: {
      color: '#0075B7',
      fontSize: '14px',
      fontWeight: 600,
      borderBottom: '1px solid #0075B7',
      marginLeft: '8px',
    },
  }),
);

export default useStyles;
