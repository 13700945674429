import * as React from 'react';
import { UserType, InitiateAuthResponse } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import jwtDecode from 'jwt-decode';
import { CodePanel } from '../';
import TopBar from '../../header/Header';
import {
  verifyAttribute,
  getAttributeVerificationCode,
  authenticateUser,
  getMe,
  refreshTokens,
  emailUsed,
} from '../../../api/user';
import { ButtonBar, ValTextField } from '../../../components';
import { AuthenticateUserConfig, Status } from '../../../types';
import { getAttributeFromUserType, getRedirectUrl, onEnterFocusNext, updateLocalTokens } from '../../../utils';
import { useStyles } from '.';
import { Formik, Form } from 'formik';
import { NormalLoginValidations } from '../../../validations';
import { useFormatMessage } from 'evl-ui-lib';
import { ChangeEmail } from '..';
import { EvlBox, EvlContainer, EvlGrid, EvlLink, EvlTypography, EvlSnackbar } from 'evl-ui-components';
import { useRouter } from 'next/router';
import { Portal } from '@material-ui/core';

// const { publicRuntimeConfig } = getConfig();

interface NormalLoginPanelState {
  username: string;
  password: string;
}

interface Claims {
  exp: any;
}

interface NormalLoginPanelProps {
  forgotPasswordClick(): void;
  registerClick(): void;
  returnHomeClick: () => void;
  redirectPage: string;
  verify?: string;
}

const NormalLoginPanel = ({ forgotPasswordClick, registerClick, redirectPage, verify }: NormalLoginPanelProps) => {
  const classes = useStyles();
  const translate = useFormatMessage();
  const router = useRouter();

  const initialState: NormalLoginPanelState = {
    username: '',
    password: '',
  };

  const [state, setState] = React.useState<NormalLoginPanelState>(initialState);
  const [showCodePanel, setShowCodePanel] = React.useState<
    'verification' | 'confirmation' | 'changePhoneNumber' | undefined
  >(undefined);
  const [email, setEmail] = React.useState<string>('');

  const [fetchLoginStatus, setFetchLoginStatus] = React.useState<Status>(Status.blank);
  const [submitCodeStatus, setSubmitCodeStatus] = React.useState<Status>(Status.blank);
  const initialCodeValues: string[] = ['', '', '', '', '', ''];
  const [codeValues, setCodeValues] = React.useState<String[]>(initialCodeValues);
  const [resendCodeStatus, setResendCodeStatus] = React.useState<Status>(Status.blank);
  const [failureMessage, setFailureMessage] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [codeError, setCodeError] = React.useState<boolean>(false);

  const styleProps = {
    background: '#FFFFFF',
    marginLeft: '8px',
    width: 'calc(100% - 16px)',
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    verify && setShowCodePanel('changePhoneNumber');
  }, [verify]);

  React.useEffect(() => {
    if (failureMessage !== '') {
      setFetchLoginStatus(Status.failure);
      return;
    }
  }, [failureMessage]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (fetchLoginStatus !== Status.inProgress) return;
      if (submitCodeStatus !== Status.success) {
        try {
          const authConfig: AuthenticateUserConfig = {
            username: state.username.toUpperCase(),
            password: state.password,
          };
          const result: InitiateAuthResponse = await authenticateUser(authConfig);
          if (result.AuthenticationResult) {
            const token = result.AuthenticationResult.AccessToken || '';
            const idToken = result.AuthenticationResult.IdToken || '';
            const refreshToken = result.AuthenticationResult.RefreshToken || '';
            let claims: Claims = jwtDecode(token || '');
            localStorage.setItem('access_token', token);
            localStorage.setItem('token', token);
            localStorage.setItem('id_token', idToken);
            localStorage.setItem('refresh_token', refreshToken);
            localStorage.setItem('token_exp', claims.exp);
            const me: UserType = await getMe();
            if (
              getAttributeFromUserType({
                userType: me,
                attrName: 'email_verified',
              }) === 'false'
            ) {
              setEmail(
                getAttributeFromUserType({
                  userType: me,
                  attrName: 'email',
                }),
              );
              setShowCodePanel('changePhoneNumber');
              setFetchLoginStatus(Status.blank);
              return;
            }
            const page = decodeURIComponent(redirectPage);
            const routerPage =
              page === 'undefined'
                ? '/'
                : getRedirectUrl({
                    originalRedirectPage: page,
                  });
            window.location.href = routerPage;
          }
        } catch (err) {
          if (err.message === 'Password attempts exceeded') {
            err.message = translate('login.exceeded');
          } else if (err.message === 'Incorrect username or password.') {
            err.message = translate('login.incorrect');
          } else {
            try {
              await emailUsed(state.username);
              err.message = translate('login.support');
            } catch (err) {
              err.message = translate('login.incorrect');
            }
          }

          setFailureMessage(err.message);
          setFetchLoginStatus(Status.failure);
          setOpen(true);
        }
      } else {
        try {
          const refreshResp = await refreshTokens();
          updateLocalTokens(refreshResp.AuthenticationResult);
          const page = decodeURIComponent(redirectPage);
          const routerPage =
            page === 'undefined'
              ? '/'
              : getRedirectUrl({
                  originalRedirectPage: page,
                });
          window.location.href = routerPage;
        } catch (err) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('id_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('token_exp');
          router.push('/');
        }
      }
    };
    fetchData();
  }, [fetchLoginStatus]);

  React.useEffect(() => {
    const submitCode = async () => {
      const verificationCode = codeValues.join('');
      if (submitCodeStatus !== Status.inProgress) return;
      if (verificationCode.length !== codeValues.length) {
        setCodeError(true);
        setSubmitCodeStatus(Status.failure);
        return;
      }
      try {
        await verifyAttribute({
          code: verificationCode,
          attributeName: 'email',
        });
        setSubmitCodeStatus(Status.success);
        setFetchLoginStatus(Status.inProgress);
      } catch (err) {
        setFailureMessage(translate('codePanel.wrongCode'));
        setOpen(true);
        setSubmitCodeStatus(Status.failure);
      }
    };
    submitCode();
  }, [submitCodeStatus]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (resendCodeStatus === Status.inProgress) {
        try {
          await getAttributeVerificationCode({ attributeName: 'email' });
          setResendCodeStatus(Status.success);
        } catch {
          setResendCodeStatus(Status.failure);
        }
      }
    };
    fetchData();
  }, [resendCodeStatus]);

  const handleCodePanelNextClick = () => {
    setSubmitCodeStatus(Status.inProgress);
  };

  const handlePanelBack = () => {
    if (showCodePanel === 'changePhoneNumber') {
      setShowCodePanel(undefined);
    } else if (showCodePanel === 'verification') {
      setShowCodePanel('changePhoneNumber');
      setCodeValues(initialCodeValues);
      setCodeError(false);
    } else {
      router.push('/login');
    }
  };

  const updateEmail = (email: string) => {
    setEmail(email);
  };

  const updatePanel = (panel: 'verification' | 'confirmation' | 'changePhoneNumber' | undefined) => {
    setShowCodePanel(panel);
  };

  const handleLoginClick = (values: any) => {
    setState(values);
    setFetchLoginStatus(Status.inProgress);
  };

  const handleResendClick = () => {
    setResendCodeStatus(Status.inProgress);
  };

  const onChangePhoneNumber = () => {
    setShowCodePanel('changePhoneNumber');
  };

  const renderUsernamePanel = (error: boolean) => (
    <ValTextField
      id="textfield-login-username"
      name="username"
      value={state.username}
      label={translate('field.label.email')}
      autoCapitalize="off"
      onKeyDown={onEnterFocusNext}
      error={error}
    />
  );

  const renderPasswordPanel = (error: boolean) => (
    <ValTextField
      id="textfield-login-password"
      value={state.password}
      autoComplete="current-password"
      label={translate('field.label.password')}
      type="password"
      name="password"
      isPasswordField
      error={error}
    />
  );

  const handleClose = () => {
    setOpen(false);
  };

  const renderLoginPanel = () => (
    <React.Fragment>
      <Portal>
        <div className={classes.snackbar}>
          <EvlSnackbar open={open} success={false} message={failureMessage} handleClose={handleClose} duration={5000} />
        </div>
      </Portal>
      <EvlBox pt={3.6} className={classes.authContainer}>
        <EvlContainer maxWidth="sm">
          <EvlGrid container justify="center" alignItems="center" spacing={3}>
            <EvlGrid item xs={12}>
              <EvlTypography color="textPrimary" variant="h5" className={classes.title}>
                {translate('login.title')}
              </EvlTypography>
              <EvlTypography color="textSecondary" variant="body2">
                {translate('login.subtitle')}
              </EvlTypography>
            </EvlGrid>
            <EvlGrid item xs={12}>
              <Formik
                validateOnBlur={true}
                validateOnChange={true}
                validateOnMount={true}
                validationSchema={NormalLoginValidations(translate)}
                onSubmit={handleLoginClick}
                initialValues={initialState}
              >
                {({ errors, touched }) => (
                  <Form>
                    <EvlGrid container justify="center" alignItems="center" spacing={3}>
                      <EvlGrid item xs={12}>
                        <EvlGrid container justify="center" alignItems="center" spacing={2}>
                          <EvlGrid item xs={12} className={classes.username}>
                            {renderUsernamePanel(errors.username !== undefined && touched.username === true)}
                          </EvlGrid>
                          <EvlGrid item xs={12}>
                            {renderPasswordPanel(errors.password !== undefined && touched.password === true)}
                          </EvlGrid>
                          <div className={classes.forgotWrapper}>
                            <EvlLink onClick={forgotPasswordClick} className={classes.forgotPassword}>
                              {translate('link.forgotCredentials')}
                            </EvlLink>
                          </div>
                        </EvlGrid>
                      </EvlGrid>
                      <EvlGrid item xs={12}>
                        <ButtonBar
                          buttonList={[
                            {
                              buttonId: 'login-submit-button',
                              buttonType: 'submit' as 'submit',
                              buttonStyle: 'primary',
                              buttonLabel: translate('button.login'),
                              isFormButton: true,
                              buttonInProgress: fetchLoginStatus === Status.inProgress,
                            },
                          ]}
                        />
                      </EvlGrid>
                    </EvlGrid>
                  </Form>
                )}
              </Formik>
            </EvlGrid>
          </EvlGrid>
        </EvlContainer>
      </EvlBox>
      <div className={classes.footer}>
        <EvlTypography color="textSecondary" variant="body2" className={classes.footerText}>
          {translate('login.noAccount')}
        </EvlTypography>
        <EvlLink onClick={registerClick} className={classes.signup}>
          {translate('button.register')}
        </EvlLink>
      </div>
    </React.Fragment>
  );

  const renderCodePanel = () => (
    <EvlContainer maxWidth="sm">
      <Portal>
        <div className={classes.snackbar}>
          <EvlSnackbar open={open} success={false} message={failureMessage} handleClose={handleClose} duration={5000} />
        </div>
      </Portal>
      <EvlGrid container justify="center" alignItems="center">
        <CodePanel
          codeValues={codeValues}
          setCodeValues={setCodeValues}
          resendCodeStatus={resendCodeStatus}
          handleResendClick={handleResendClick}
          existingNumber={email}
          onChangePhoneNumber={onChangePhoneNumber}
          email={true}
          error={codeError}
        ></CodePanel>
        <EvlGrid item xs={12} className={classes.verifyButtons} spacing={3}>
          <ButtonBar
            buttonList={[
              {
                buttonId: 'forgot-password-submit-new-password-button',
                buttonType: 'submit' as 'submit',
                buttonStyle: 'primary',
                buttonLabel: translate('button.submit'),
                handleButtonClick: handleCodePanelNextClick,
              },
              {
                buttonId: 'verify-cancel-button',
                className: classes.cancelButton,
                buttonType: 'button',
                buttonLabel: translate('button.cancel'),
                isFormButton: false,
                href: '/login?panel=normalLoginPanel',
              },
            ]}
          />
        </EvlGrid>
      </EvlGrid>
    </EvlContainer>
  );

  const renderChangePhoneNumberPanel = () => <ChangeEmail updateEmail={updateEmail} updatePanel={updatePanel} />;

  return (
    <div className={classes.loginContainer}>
      <TopBar
        disableMenuDrawer
        onBack={handlePanelBack}
        title={showCodePanel ? translate('verifyEmail.title') : translate('branding.login')}
        styleProps={!showCodePanel && styleProps}
      >
        {!showCodePanel && renderLoginPanel()}
        {showCodePanel === 'verification' && renderCodePanel()}
        {showCodePanel === 'changePhoneNumber' && renderChangePhoneNumberPanel()}
      </TopBar>
    </div>
  );
};

export default NormalLoginPanel;
