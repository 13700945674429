import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useFormatMessage } from 'evl-ui-lib';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '0 23px',
      justifyContent: 'center',
    },
    message: {
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  }),
);

const BrowserNotSupportPanel = () => {
  const classes = useStyles();
  const translate = useFormatMessage();

  return (
    <div id="browser-not-supported-page" className={classes.container}>
      <p className={classes.message}>{translate('app.browserNotSupported')}</p>
    </div>
  );
};

export default BrowserNotSupportPanel;
