import * as React from 'react';
import { EvlBox, EvlGrid, EvlLink, EvlTypography } from 'evl-ui-components';
import useStyles from './FooterJSS';
import { useFormatMessage, useLocale } from 'evl-ui-lib';
import EvlButton from 'evl-ui-components/lib/components/button';
import { getMessages } from '@utils';

type Props = {
  variant?: string;
  bottom?: number;
  page?: string;
};

const Footer: React.FunctionComponent<Props> = ({ variant, bottom, page }) => {
  const classes = useStyles();
  const translate = useFormatMessage();
  const {setLocale} = useLocale();
  const handleLangChange = async (selectedLang: string) => {
    const messages = await getMessages(selectedLang);
    window.localStorage.setItem('locale', selectedLang);
    await setLocale(selectedLang, messages);
  }

  return (
    <React.Fragment>
      <EvlBox
        component="footer"
        textAlign="center"
        className={classes.evlFooterBox}
        style={bottom !== undefined ? { bottom: bottom } : {}}
      >
        {page && page === 'introPanel' && 
          <EvlBox component="div" className={classes.languageSelectorFooter}>
            <EvlGrid className={classes.languageSelectorContainer}>
              <EvlButton onClick={() => handleLangChange('en')} variant="text" size='small' className={classes.languageSelector} style={{ color: variant && variant === 'light' ? '#FFFFFF' : '#383B41' }}>
                {translate('language.english')}
              </EvlButton>
              <EvlButton onClick={() => handleLangChange('es')} variant="text" size='small' className={classes.languageSelector} style={{ color: variant && variant === 'light' ? '#FFFFFF' : '#383B41' }}>
                {translate('language.spanish')}
              </EvlButton>
            </EvlGrid>
          </EvlBox>
        }
        <EvlGrid container justify="center" alignItems="center" spacing={0}>
          <EvlGrid item xs={12}>
            <EvlTypography variant="body2" style={{ color: variant && variant === 'light' ? '#FFFFFF' : '#383B41' }}>
              {'Powered by'}
            </EvlTypography>
          </EvlGrid>
          <EvlGrid item xs={12}>
            <EvlBox component="figure" style={{ margin: '0 auto' }}>
              <EvlLink target="_blank" href="http://everledger.io/">
                <img
                  className={classes.evlBrand}
                  src={variant && variant === 'light' ? '/static/images/EL_logo_light.svg' : '/static/images/logo.svg'}
                  alt=""
                />
              </EvlLink>
            </EvlBox>
          </EvlGrid>
        </EvlGrid>
      </EvlBox>
    </React.Fragment>
  );
};
export default Footer;
