import Router from 'next/router';
import queryString from 'query-string';
import React from 'react';
import { ForgotPasswordPanel, IntroPanel, NormalLoginPanel } from '../login';
import { RegisterPanel } from './register';

interface LoginPanelProps {
  redirectPage: string;
  onLandingPage: boolean;
  panel: string | undefined;
  verify?: string;
  required?: string;
  bypassComingSoon?: string;
}

const REGISTER_PANEL = 'registerPanel';
const FORGOT_PASSWORD_PANEL = 'forgotPasswordPanel';
const NORMAL_LOGIN_PANEL = 'normalLoginPanel';

const LoginPanel = ({ redirectPage, panel, bypassComingSoon, verify, required }: LoginPanelProps) => {
  const handleReturnHomeClick = () => {
    const qs = queryString.stringify(
      { redirectPage, bypassComingSoon },
      {
        skipNull: true,
        encode: false,
      },
    );
    const href: string = `?${qs}`;
    const as: string = href;
    Router.push(href, as);
  };

  const handleForgotPasswordButtonClick = () => {
    const qs = queryString.stringify(
      { redirectPage, panel: 'forgotPasswordPanel', bypassComingSoon },
      {
        skipNull: true,
        encode: false,
      },
    );
    const href: string = `?${qs}`;
    const as: string = href;
    Router.push(href, as);
  };

  const handleNormalLoginButtonClick = () => {
    const qs = queryString.stringify(
      { redirectPage, panel: 'normalLoginPanel', bypassComingSoon },
      {
        skipNull: true,
        encode: false,
      },
    );
    const href: string = `?${qs}`;
    const as: string = href;
    Router.push(href, as);
  };

  const handleRegisterButtonClick = () => {
    const qs = queryString.stringify(
      { redirectPage, panel: 'registerPanel', bypassComingSoon },
      {
        skipNull: true,
        encode: false,
      },
    );
    const href: string = `?${qs}`;
    const as: string = href;
    Router.push(href, as);
  };

  return (
    <React.Fragment>
      {panel ? (
        (panel === NORMAL_LOGIN_PANEL && (
          <NormalLoginPanel
            forgotPasswordClick={handleForgotPasswordButtonClick}
            registerClick={handleRegisterButtonClick}
            returnHomeClick={handleReturnHomeClick}
            redirectPage={redirectPage}
            verify={verify}
          />
        )) ||
        (panel === FORGOT_PASSWORD_PANEL && <ForgotPasswordPanel />) ||
        (panel === REGISTER_PANEL && (
          <RegisterPanel returnHomeClick={handleReturnHomeClick} redirectPage={redirectPage} required={required} />
        ))
      ) : (
        <IntroPanel
          handleLoginButtonClick={handleNormalLoginButtonClick}
          handleRegisterButtonClick={handleRegisterButtonClick}
          redirectPage={redirectPage}
        />
      )}
    </React.Fragment>
  );
};

export default LoginPanel;
