import React from 'react';
import getConfig from 'next/config';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Checkbox } from '@material-ui/core';
import { CountryCodeNew } from '../../types';

const { publicRuntimeConfig } = getConfig();
const { NEW_CLOUDFRONT_URL, PROD_EVL_CLIENT_ID_FOR_S3 } = publicRuntimeConfig;

interface DCCOption {
  name: string;
  preCheckboxParagraphs: Array<string>;
  privacyPolicyCheckBoxes: Array<string>;
  marketingCheckBox?: string;
  postParagraphs: Array<string>;
}

const option1: DCCOption = {
  name: 'option1',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. This will allow Everledger to offer you a personalized and tailored experience, provide you with products, services and information you request from Everledger and communicate with you. All your personal information will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you. By creating your Everledger profile, you confirm that you have read, understood and that you agree to our privacy policy.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time.',
  ],
  privacyPolicyCheckBoxes: ['I agree to the privacy policy*.'],
  postParagraphs: [
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parent or legal guardian also agrees to such registration).',
  ],
};

const option2: DCCOption = {
  name: 'option2',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. This will allow Everledger to offer you a personalized and tailored experience, provide you with products, services and information you request from Everledger and communicate with you. All your personal information will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
  ],
  privacyPolicyCheckBoxes: ['I have read and understood the privacy policy*.'],
  marketingCheckBox:
    'I would like to receive updates about Everledger new activities, exclusive products, tailored services and to have a personalised client experience based on my interests.',
  postParagraphs: [
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parent or legal guardian also agrees to such registration).',
  ],
};

const option3: DCCOption = {
  name: 'option3',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. This will allow Everledger to offer you a personalized and tailored experience, provide you with products, services and information you request from Everledger and communicate with you.',
    'All your personal information will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
  ],
  privacyPolicyCheckBoxes: [
    'I have read and understood the privacy policy*.',
    'I understand that Everledger will draw inferences based on my profile information (profiling)*.',
  ],
  marketingCheckBox:
    ' I would like to receive updates about Everledger new activities, exclusive products and tailored services.',
  postParagraphs: [
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parent or legal guardian also agrees to such registration).',
  ],
};

const option4: DCCOption = {
  name: 'option4',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. This will allow Everledger to offer you a personalized and tailored experience, provide you with products, services and information you request from Everledger and communicate with you.',
    'All your personal information will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
  ],
  privacyPolicyCheckBoxes: ['I have read and understood the privacy policy*.'],
  marketingCheckBox:
    'I would like to receive updates about Everledger new activities, exclusive products, tailored services and to have a personalised client experience based on my interests, and I agree to the use of my personal information for this purpose.',
  postParagraphs: [
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parent or legal guardian also agrees to such registration).',
  ],
};

const option5: DCCOption = {
  name: 'option5',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. This will allow Everledger to offer you a personalized and tailored experience, provide you with products, services and information you request from Everledger and communicate with you. All your personal information will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you. By creating your Everledger profile, you confirm that you have read, understood and that you agree to our privacy policy.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
  ],
  privacyPolicyCheckBoxes: ['I agree to the privacy policy*.'],
  postParagraphs: [
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence.',
  ],
};

const option6: DCCOption = {
  name: 'option6',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. This will allow Everledger to offer you a personalized and tailored experience, provide you with products, services and information you request from Everledger and communicate with you. All your personal information will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you. By creating your Everledger profile, you confirm that you have read, understood and that you agree to our privacy policy.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
  ],
  privacyPolicyCheckBoxes: ['I agree to the privacy policy*.'],
  postParagraphs: [
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parents or legal guardians also agree to such registration).',
  ],
};

const option7: DCCOption = {
  name: 'option7',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. This will allow Everledger to offer you a personalized and tailored experience, provide you with products, services and information you request from Everledger and communicate with you. All your personal information will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
  ],
  privacyPolicyCheckBoxes: ['I have read and understood the privacy policy*.'],
  marketingCheckBox:
    'I would like to receive  updates (including by email, SMS, MMS, social media, phone, physical letter) about Everledger new activities, exclusive products, tailored services and to have a personalised client experience based on my interests.',
  postParagraphs: [
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parent or legal guardian also agrees to such registration).',
  ],
};

const option8: DCCOption = {
  name: 'option8',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. This will allow Everledger to offer you a personalized and tailored experience, provide you with products, services and information you request from Everledger and communicate with you. All your personal information will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you. By creating your Everledger profile, you confirm that you have read, understood and agree to our privacy policy.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
  ],
  privacyPolicyCheckBoxes: [
    'I agree to the privacy policy*.',
    'I understand that Everledger will draw inferences based on my profile information (profiling)*.',
  ],
  marketingCheckBox:
    'I would like to receive  updates about Everledger new activities, exclusive products and tailored services (including via phone, text messages and instant messaging applications).',
  postParagraphs: [
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parent or legal guardian also agrees to such registration).',
  ],
};

const option9: DCCOption = {
  name: 'option9',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. This will allow Everledger to offer you a personalized and tailored experience, provide you with products, services and information you request from Everledger and communicate with you. All your personal information will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you. You will find additional information about the collection, use, processing, storage, disclosure and transfer of your personal information in our privacy policy. By creating your Everledger profile, you confirm that you have read, understood and that you agree to our privacy policy.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
  ],
  privacyPolicyCheckBoxes: ['I agree to the privacy policy*.'],
  marketingCheckBox:
    'I agree to receive  updates about Everledger new activities, exclusive products, tailored services and to have a personalised client experience based on my interests.',
  postParagraphs: [
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parent or legal guardian also agrees to such registration).',
  ],
};

const option10: DCCOption = {
  name: 'option10',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. Everledger will use your profile to provide you with the products, services and information you purchase or request from Everledger, offer you customized assistance and customer service, send you tailored promotional communications, conduct our business securely and in accordance with our obligations, and optimize and improve our services. All your personal information will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you. By creating your Everledger profile, you confirm that you have read, understood and that you agree to our privacy policy.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
  ],
  privacyPolicyCheckBoxes: ['I agree to the privacy policy*.'],
  marketingCheckBox:
    'I would like to receive  updates about Everledger new activities, exclusive products, tailored services and to have a personalised client experience based on my interests.',
  postParagraphs: [
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parent or legal guardian also agrees to such registration).',
  ],
};

const option11: DCCOption = {
  name: 'option11',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. This will allow Everledger to offer you a personalized and tailored experience, provide you with products, services and information you request from Everledger and communicate with you. All your personal information will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
  ],
  privacyPolicyCheckBoxes: ['I acknowledge the terms of privacy policy*.'],
  marketingCheckBox:
    'I would like to receive information and updates about Everledger new activities, exclusive products, tailored services (including through phone, SMS, MMS and instant messaging applications), and to have a personalised client experience based on my interests.',
  postParagraphs: [
    'By agreeing to receiving customised information and updates, you authorize Everledger to deliver marketing calls and messages to you, including using an automatic telephone dialing system or an artificial or prerecorded voice, to the phone number provided on your profile. Everledger does not require you to agree to receive such calls or messages as a condition of purchasing any products or services.',
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parent or legal guardian also agrees to such registration).',
  ],
};

const option12: DCCOption = {
  name: 'option12',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. This will allow Everledger to offer you a personalized and tailored experience, provide you with products, services and information you request from Everledger and communicate with you. All your personal information will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
    'We may collect identifiers such as a real name, alias, postal address, unique personal identifier, online identifier Internet Protocol address, email address, account name, social security number, driver’s license number, passport number, or other similar identifiers, including information about your vehicle. This set of personal information enables us to identify you, manage your Everledger profile, provide you with customer service and assistance and, in some cases, to contact you, including by sending you relevant information and tailored marketing content.',
    "We may collect information that identifies, relates to, describes, or is capable of being associated with, a particular individual, including, but not limited to, your name, signature, social security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. This set of personal information enables us to identify you, manage your Everledger profile, provide you with customer service and assistance and, in some cases, to contact you, including by sending you relevant information and tailored marketing content.",
    'We may collect commercial information, including records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies. This set of personal information enables us to provide you with the products and services you have requested, manage your Everledger profile, provide you with relevant customer service and assistance, secure our communications and to manage and better understand our business.',
    'We may collect internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement. This set of personal information enables us to always offer you the optimum service by understanding you better. We use it to manage your Everledger profile, provide you with relevant customer service and assistance and also, in certain circumstances, to contact you for example by sending you relevant information, updates and tailored marketing content.',
    'We may collect geolocation data. This set of personal information enables better delivery of the service you expect from us. It could include personal information related to your interactions with us and allows us to manage your Everledger profile, provide relevant customer service and tailored marketing content, establish communications and conduct our business securely, assess and better understand our business, and  optimise and manage our website and other means of communication.',
    'We may collect audio, electronic, visual, thermal, olfactory, or similar information. This data (e.g. recordings of customer service calls) is processed to answer your queries, detect security incidents, protect against malicious, deceptive, fraudulent or illegal activity, and for compliance management.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
  ],
  privacyPolicyCheckBoxes: ['I acknowledge the terms of privacy policy*.'],
  marketingCheckBox:
    'I would like to receive information and updates about Everledger new activities, exclusive products, tailored services (including through phone, SMS, MMS and instant messaging applications), and to have a personalised client experience based on my interests.',
  postParagraphs: [
    'By agreeing to receiving customised information and updates, you authorize Everledger to deliver marketing calls and messages to you, including using an automatic telephone dialing system or an artificial or prerecorded voice, to the phone number provided on your profile. Everledger does not require you to agree to receive such calls or messages as a condition of purchasing any products or services.',
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parent or legal guardian also agrees to such registration).',
  ],
};

const option13: DCCOption = {
  name: 'option13',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. This will allow Everledger to offer you a personalized and tailored experience, provide you with products, services and information you request from Everledger and communicate with you. All your personal information will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
  ],
  privacyPolicyCheckBoxes: ['I have read and understood the privacy policy*.'],
  marketingCheckBox:
    'I would like to receive information and updates about Everledger new activities, exclusive products, tailored services (including through phone, SMS, MMS, instant messaging applications and email), and to have a personalised client experience based on my interests.',
  postParagraphs: [
    'By agreeing to receiving customised information and updates, you authorize Everledger to deliver marketing calls and messages to you, including using an automatic telephone dialing system or an artificial or prerecorded voice, to the phone number provided on your profile. Everledger does not require you to agree to receive such calls or messages as a condition of purchasing any products or services.',
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parent or legal guardian also agrees to such registration).',
    'Unless otherwise required in your country of residence, Everledger will not be responsible for carrier charges that may apply.',
  ],
};

const option14: DCCOption = {
  name: 'option14',
  preCheckboxParagraphs: [
    'You are about to create your Everledger profile. This will allow Everledger to offer you a personalized and tailored experience, provide you with products, services and information you request from Everledger and communicate with you.',
    'All your personal information provided in the registration form and other personal information that you share voluntarily with sales assistants or otherwise (e.g. via social media, cookies, etc.) (hereinafter “Personal Information”) will be kept, used and linked to your Everledger profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. Subject to local law, we will retain your Personal Information for 5 years from the last contact you initiated with Everledger. Last Contact shall be defined as the last contact initiated by the customer and traceable by our systems or a sales associate. As examples of such contacts there could be the last time a call, sales email, or meeting was proposed to you to which you responded favorably by, for instance clicking on the link to our website included in the email or going to one of our stores following a proposal for dedicated discount. Opening an email from Everledger would not qualify as Last Contact. Clicking on a link included in an email would. Going into one of our stores would.',
    'As we operate globally, we may securely share your Personal Information with companies of our group and approved partners located around the world (list of companies and approved partners listed at: [URL or other publicly available references]) for the same purposes as Everledger for the period stipulated above. We will implement safeguards to protect your personal information.',
    'We may use any contact information you share with us to communicate with you on your interests and shopping habits, through various communication channels including SMS, MMS, email, physical letter, internet or social media or phone, to improve our services, notably via profiling, and to conduct lookalike targeting campaigns by the selection of your advertising impressions across our websites where applicable for the period stipulated above.',
    'By creating your Everledger profile, you confirm that you have read and understood our privacy policy. You may be unable to receive the products and services offered by Everledger if you refuse to consent to the collection, use or provision to third parties of Personal Information that is necessary for the provision of such products and services.',
    'Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at privacy@Everledger.com.',
  ],
  privacyPolicyCheckBoxes: [
    'I agree that Everledger collects and uses my Personal Information*.',
    'I agree that Everledger may provide my Personal Information to third parties*.',
  ],
  marketingCheckBox:
    'I agree to receive tailored information and updates about Everledger new activities, exclusive products, tailored services and to have a personalised experience based on my interests.',
  postParagraphs: [
    'It is mandatory to tick the box with (*) to create your Everledger profile.',
    'By creating your Everledger profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parent or legal guardian also agrees to such registration).',
  ],
};

interface GetDCCOptionsFromCountryProps {
  country: CountryCodeNew;
  location: string;
}

const getDCCOptionsFromCountry: ({ country, location }: GetDCCOptionsFromCountryProps) => DCCOption = ({
  country,
  location,
}: GetDCCOptionsFromCountryProps) => {
  switch (country.countryAbbr) {
    case 'MX':
    case 'CN':
    case 'TW':
    case 'KW':
    case 'AE':
      return option1;
    case 'IT':
      return option3;
    case 'DE':
      return option4;
    case 'BR':
      return option5;
    case 'CL':
      return option6;
    case 'AU':
      return option7;
    case 'SG':
      return option8;
    case 'VN':
      return option9;
    case 'QA':
      return option10;
    case 'US': {
      if (location.toLowerCase().indexOf('california') === -1) return option11;
      return option12;
    }
    case 'GU':
      return option13;
    case 'KR':
      return option14;
    default:
      return option2;
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dccRoot: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingTop: theme.spacing(1),
    },
    dccCheckboxWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    dccCheckbox: {
      color: theme.palette.primary.main,
      paddingLeft: 0,
      position: 'relative',
      left: '-4px',

      '&$checked': {
        color: theme.palette.primary.light,
      },

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    dccTitle: {
      textAlign: 'left',
      padding: theme.spacing(1, 0),
    },
    dccBody: {
      textAlign: 'left',
      padding: theme.spacing(1, 0),
    },
    dccAsterisk: {
      textAlign: 'left',
      padding: theme.spacing(1, 0),
    },
    dccLink: {
      color: theme.palette.primary.light,
      cursor: 'pointer',
      textAlign: 'left',
      textDecoration: 'underline',
      padding: `0px ${theme.spacing(0.5)}px`,
    },
  }),
);

interface DCCProps {
  country: CountryCodeNew;
  location: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const DCC = ({ country, location, setFieldValue }: DCCProps) => {
  const classes = useStyles();
  const dcc: DCCOption = getDCCOptionsFromCountry({ country, location });

  const [ppChecked, setPPChecked] = React.useState<Array<boolean>>(dcc.privacyPolicyCheckBoxes.map(() => false));

  const privacyPolicyUrl = `${NEW_CLOUDFRONT_URL}/${PROD_EVL_CLIENT_ID_FOR_S3}/files/PrivacyPolicy_EN.pdf`;
  const termsAndConditionsUrl = `${NEW_CLOUDFRONT_URL}/${PROD_EVL_CLIENT_ID_FOR_S3}/files/TermsAndConditions_EN.pdf`;

  React.useEffect(() => {
    setPPChecked(dcc.privacyPolicyCheckBoxes.map(() => false));
    setFieldValue('agreesToPP', false);
  }, [dcc]);

  React.useEffect(() => {
    if (ppChecked.includes(false)) {
      setFieldValue('agreesToPP', false);
      return;
    }
    setFieldValue('agreesToPP', true);
  }, [ppChecked]);

  const handlePPChange = (index: number) => () => {
    setPPChecked([...ppChecked.slice(0, index), !ppChecked[index], ...ppChecked.slice(index + 1)]);
  };

  return (
    <div className={classes.dccRoot}>
      <Typography className={classes.dccBody} variant="body1">
        {'You are invited to read the'}
        <Link
          href={privacyPolicyUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.dccLink}
          variant="body1"
        >
          {'Privacy Policy'}
        </Link>
        {'and'}
        <Link
          href={termsAndConditionsUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.dccLink}
          variant="body1"
        >
          {'Terms and Conditions'}
        </Link>
        {'in full.'}
      </Typography>
      {dcc.privacyPolicyCheckBoxes.map((checkBoxText: string, index: number) => (
        <div className={classes.dccCheckboxWrapper}>
          <Checkbox
            id={`checkbox-register-required-${index}`}
            checked={ppChecked[index]}
            onChange={handlePPChange(index)}
            color="default"
            classes={{
              root: classes.dccCheckbox,
            }}
          />
          <Typography className={classes.dccBody} variant="body1">
            {checkBoxText}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default DCC;
