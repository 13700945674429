import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { baseTextField, inputField, inputLabel, invalidInput, authContainerSetup } from '../../../jss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    authContainer: {
      ...authContainerSetup(theme).default,
      paddingBottom: '90px',
    },
    btnAuth: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.light,
      padding: theme.spacing(1, 1),
      textTransform: 'none',
      borderRadius: theme.shape.borderRadius * 2,
      height: theme.spacing(4),

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    evlHeader: {
      maxWidth: theme.spacing(20),
      margin: `0 auto`,

      '& $img': {
        width: '100%',
      },
    },
    evlBrand: {
      width: '100%',
      margin: `${theme.spacing(0)} auto`,
      maxWidth: '100%',
    },
    topSectionContainer: {
      width: '100%',
    },
    logoContainer: {
      width: '60%',
      minWidth: 160,
      minHeight: 88,

      '& img': {
        width: '100%',
      },
    },
    titleContainer: {
      marginBottom: '0',
      padding: `0 ${theme.spacing(2)}px`,
    },
    textFields: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flexStart',
      width: '100%',
    },
    textField: {
      ...baseTextField(theme).default,
      margin: 0,
    },
    textFieldIcon: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.primary.contrastText,
      width: theme.spacing(5.2),
      height: theme.spacing(5.2),
      border: `0px`,
      '&:hover': {
        borderColor: theme.palette.secondary.light,
      },
    },
    textLabel: {
      ...inputLabel(theme).default,
      color: 'transparent',
    },
    textLabelFocused: {
      ...inputLabel(theme).default,
      color: 'transparent',
    },
    textInput: inputField(theme).default,
    logInButtonPanel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: `${theme.spacing(3)}px 0px 0px 0px`,
    },
    loginButton: {
      width: '30%',
      maxWidth: '160px',
    },
    backButton: {
      width: '30%',
      maxWidth: '160px',
    },
    invalid: invalidInput(theme).default,
    icon: {
      color: theme.palette.primary.main,
    },
    registerButton: {
      padding: 0,
    },
    buttonLabel: {},
    disabledButton: {
      color: 'white !important',
      opacity: 0.5,
    },
    buttonsContainer: {
      marginTop: theme.spacing(3),
      width: '100%',

      '& button:first-child': {
        marginRight: '12px',
      },
    },
    changeEmailContainer: {
      width: '100%',
      position: 'relative',
    },
    separator: {
      width: '100%',
      position: 'relative',

      '&:after, &:before': {
        content: "''",
        position: 'absolute',
        height: theme.spacing(0.1),
        backgroundColor: theme.palette.secondary.light,
        width: `${theme.spacing(4.5)}%`,
        top: theme.spacing(1.3),
      },

      '&:after': {
        right: theme.spacing(0),
      },

      '&:before': {
        left: theme.spacing(0),
      },
    },
    separatorText: {
      color: '#7C888D',
    },
    submit: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '20px',
      margin: '0px 20px',
    },
    codeContainer: {
      position: 'relative',
      minHeight: 'calc(100vh - 76px)',
      textAlign: 'center',
    },
    title: {
      fontWeight: 700,
      fontFamily: 'Lato',
      marginBottom: '4px',
    },
    loginContainer: {
      backgroundColor: theme.palette.primary.main,
      minHeight: '476px',
      height: '100%',
      width: '100%',
      position: 'absolute',
    },
    forgotWrapper: {
      width: '100%',
      padding: '0px 10px 10px',
      textAlign: 'right',
      marginTop: '-15px',
    },
    forgotPassword: {
      color: '#0075B7',
      fontSize: '14px',
      fontWeight: 600,
      borderBottom: '1px solid #0075B7',
    },
    username: {
      paddingBottom: '14px !important',
    },
    signup: {
      color: '#0075B7',
      fontSize: '14px',
      fontWeight: 600,
      borderBottom: '1px solid #0075B7',
      marginLeft: '8px',
    },
    footer: {
      position: 'absolute',
      bottom: '11px',
      width: '100%',
      textAlign: 'center',
    },
    footerText: {
      display: 'inline',
    },
    snackbar: {
      '& > div': {
        width: 'calc(100% - 17px)',
        maxWidth: '540px',
        bottom: 'auto',

        '& > div': {
          width: '100%',
        },
      },
    },
    verifyButtons: {
      position: 'absolute',
      bottom: '0px',
      width: '100%',
      maxWidth: '600px',
      padding: '20px !important',
    },
    cancelButton: {
      marginTop: '12px',
      backgroundColor: '#E7EBED',
      color: '#062734',

      '&:hover': {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #E7EBED',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      },
    },
  }),
);

export default useStyles;
