import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import { TitlePanel } from '../../components';

const styles = (theme: Theme) =>
  createStyles({
    panelTitle: {
      textAlign: 'center',
    },
    successBody: {
      textAlign: 'center',
    },
    successImage: {
      padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    },
    checkIcon: {
      color: 'white',
      fontSize: '2rem',
      marginBottom: theme.spacing(2),
    },
  });

interface SuccessPanelProps extends WithStyles<typeof styles> {
  successTitle: string;
  successBody: string;
}

const SuccessPanel: React.SFC<SuccessPanelProps> = ({ classes, successTitle, successBody }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <CheckIcon classes={{ root: classes.checkIcon }} />
      <TitlePanel title={successTitle} description={successBody} hideBottomBorder />
    </React.Fragment>
  );
};

export default withStyles(styles)(SuccessPanel);
