import PasswordValidCheckList from './PasswordValidCheckList';

import { FormatMessage } from 'evl-ui-lib/lib/utils/i18n/formatMessage';

import { passwordValidationSchema } from '../../../validations';

export default PasswordValidCheckList;

export function validatePassword(translate: FormatMessage, password: string) {
  try {
    passwordValidationSchema(translate).validateSync(password, { abortEarly: false });
    return '';
  } catch (e) {
    return JSON.stringify(e.errors);
  }
}
