import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Popper } from '@material-ui/core';
import React from 'react';
import { countryCodes } from '../constants';
import { CountryCodeNew } from '../types';
import { CustomAutoComplete } from './';
import { ValTextField } from '../components';
import { useFormatMessage } from 'evl-ui-lib';
import { EvlTypography, EvlGrid } from 'evl-ui-components';

const useStyles = makeStyles(() =>
  createStyles({
    phoneNumberField: {
      width: '72%',
      marginLeft: 'auto',
    },
    countryCodeSelector: {
      textAlign: 'left',
      color: 'black',
      width: '100%',

      '&.menu': {
        width: 'max-content',
      },
    },
    label: {
      color: '#062734',
      fontSize: '15px',
      fontFamily: 'Gotham Book',
      marginBottom: '10px',
      marginTop: '34px',
      textAlign: 'left',
    },
    countryContainer: {
      display: 'flex',
      position: 'relative',
    },
    svg: {
      position: 'absolute',
      top: '20px',
      right: '10px',
    },
  }),
);

const PopperCountryCode = function(props: any) {
  return (
    <Popper {...props} style={{ borderRadius: '0px', width: '50vw', maxWidth: '300px' }} placement="bottom-start" />
  );
};

interface PhoneNumberFieldProps {
  id?: string;
  countryCode: CountryCodeNew;
  handleCountryCodeInput: any;
  phoneNumber: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleEnter?: (event: any) => void;
}

const PhoneNumberField = ({
  id,
  countryCode,
  handleCountryCodeInput,
  phoneNumber,
  setFieldValue,
  handleEnter,
}: PhoneNumberFieldProps) => {
  const classes = useStyles();
  const translate = useFormatMessage();

  const [countryCodeInput, setCountryCodeInput] = React.useState<string>('');

  return (
    <React.Fragment>
      <EvlTypography className={classes.label}>{`${translate(
        'forgotPassword.phoneNumberPanel.phoneNumber',
      )}`}</EvlTypography>
      <EvlGrid container justify="center" alignItems="center" spacing={1}>
        <EvlGrid item xs={4}>
          <div className={classes.countryContainer}>
            <CustomAutoComplete
              id={`${id}-country-code`}
              value={countryCode}
              onChange={(_event: React.ChangeEvent<{}>, newValue: CountryCodeNew) => {
                if (newValue) {
                  handleCountryCodeInput(newValue);
                  setFieldValue('code', newValue);
                }
              }}
              inputValue={countryCodeInput}
              onInputChange={(_event: React.ChangeEvent<{}>, newInputValue: string) => {
                setCountryCodeInput(newInputValue);
              }}
              onClose={(_event: React.ChangeEvent<{}>, reason: string) => {
                if (reason !== 'select-option') {
                  setCountryCodeInput(countryCode.countryAbbr);
                  setFieldValue('code', countryCode);
                }
              }}
              options={countryCodes}
              getOptionLabel={(option: CountryCodeNew) => {
                return `${option.countryAbbr} ${option.value}` || '';
              }}
              renderOption={(option: CountryCodeNew) => {
                return option.label;
              }}
              PopperComponent={PopperCountryCode}
              autoCapitalize="none"
              label=""
              name="code"
              className={classes.countryCodeSelector}
              noStartAdornment={true}
              hideBottomBorder
              onKeyDown={handleEnter}
            />
            <svg
              className={classes.svg}
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.60185 1L1 2.52664L7.7923 9L14.5846 2.52664L12.9828 1L7.7923 5.94673L2.60185 1Z"
                fill="#062734"
                stroke="#FAFAFA"
              />
            </svg>
          </div>
        </EvlGrid>
        <EvlGrid item xs={8}>
          <ValTextField
            id={id}
            type="tel"
            name="phoneNumber"
            value={phoneNumber}
            autoComplete="random-string"
            onKeyDown={handleEnter}
            placeholder={translate('field.placeholder.phoneNumber')}
          />
        </EvlGrid>
      </EvlGrid>
    </React.Fragment>
  );
};

export default PhoneNumberField;
