import { useState } from 'react';
import { addDemoAsset } from '../../user';

const useElasticSearch = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchResult, setSearchResult] = useState<any>();
  const [error, setError] = useState<any>();

  const elasticSearch = (query: any) => {
    setIsLoading(true);
    setError(undefined);
    addDemoAsset(query)
      .then((result: any) => onSuccess(result))
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };
  const onSuccess = (resp: any) => {
    setSearchResult(resp);
    setIsLoading(false);
    setError('');
  };

  return {
    elasticSearch,
    isLoading,
    searchResult,
    error,
  };
};

export default useElasticSearch;
