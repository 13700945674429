import * as React from 'react';
import { Formik, Form } from 'formik';
import SvgIcon from '@material-ui/core/SvgIcon';
import { EvlBox, EvlContainer, EvlGrid, EvlLink, EvlTypography, EvlSnackbar } from 'evl-ui-components';
import { useFormatMessage } from 'evl-ui-lib';

import TopBar from '../../header/Header';
import {
  VerificationToken,
  ButtonBar,
  ValTextField,
  PasswordValidationCheckList,
  validatePassword,
} from '../../../components';
import { NewEmailValidations } from '../../../validations';
import { Status } from '../../../types';
import { forgotPassword, confirmForgotPassword } from '../../../api/user';
import { onEnterFocusNext } from '../../../utils';
import { useRouter } from 'next/router';

import { useStyles } from '.';

interface ForgotPasswordState {
  email: string;
  newPassword: string;
}

const ForgotPasswordPanel = () => {
  const classes = useStyles();
  const translate = useFormatMessage();
  const router = useRouter();

  const initialState: ForgotPasswordState = {
    email: '',
    newPassword: '',
  };
  const initialCodeValues: string[] = ['', '', '', '', '', ''];

  const [state, setState] = React.useState<ForgotPasswordState>(initialState);
  const [codeValues, setCodeValues] = React.useState<String[]>(initialCodeValues);
  const [codeError, setCodeError] = React.useState<boolean>(false);
  const [fetchFPStatus, setFetchFPStatus] = React.useState<Status>(Status.blank);
  const [resendTokenStatus, setResendTokenStatus] = React.useState<Status>(Status.blank);
  const [fetchVCStatus, setFetchVCStatus] = React.useState<Status>(Status.blank);
  const initialVisiblePanel: string = 'numberPanel';
  const [visiblePanel, setVisiblePanel] = React.useState<String>(initialVisiblePanel);
  const [failureMessage, setFailureMessage] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);

  const validateCode = () => {
    const verificationCode = codeValues.join('');
    if (verificationCode.length !== codeValues.length) {
      setCodeError(true);
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      if (fetchFPStatus === Status.inProgress) {
        try {
          await forgotPassword({ username: state.email });
          setVisiblePanel('newPasswordPanel');
          setFetchFPStatus(Status.success);
        } catch (err) {
          setFailureMessage(
            err.message === 'Username/client id combination not found.'
              ? translate('forgotPassword.error.email')
              : translate('forgotPassword.error.attempts'),
          );
          setOpen(true);
          setFetchFPStatus(Status.failure);
        }
      }
    };
    fetchData();
  }, [fetchFPStatus]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (resendTokenStatus === Status.inProgress) {
        try {
          await forgotPassword({ username: state.email });
          setResendTokenStatus(Status.success);
        } catch (err) {
          setFailureMessage(
            err.message === 'Username/client id combination not found.'
              ? translate('forgotPassword.error.email')
              : translate('forgotPassword.error.attempts'),
          );
          setOpen(true);
          setResendTokenStatus(Status.failure);
        }
      }
    };
    fetchData();
  }, [resendTokenStatus]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (fetchVCStatus === Status.inProgress) {
        const { newPassword, email } = state;
        const verificationCode = codeValues.join('');
        if (verificationCode.length !== codeValues.length) {
          setCodeError(true);
          setFetchVCStatus(Status.failure);
          return;
        }
        try {
          await confirmForgotPassword({
            code: verificationCode,
            password: newPassword,
            username: email,
          });
          setFetchVCStatus(Status.success);
        } catch (err) {
          setFailureMessage(
            err.message === 'Invalid verification code provided, please try again.'
              ? translate('forgotPassword.error.code')
              : err.message === 'Attempt limit exceeded, please try after some time.'
              ? translate('forgotPassword.error.attempts')
              : translate('forgotPassword.error.numeric'),
          );
          setOpen(true);
          setFetchVCStatus(Status.failure);
        }
      }
    };
    fetchData();
  }, [fetchVCStatus]);

  const handleForgotPasswordClick = async (values: any) => {
    setState({ ...state, email: values.email });
    setFetchFPStatus(Status.inProgress);
  };

  const handleResendTokenClick = async () => {
    setFetchFPStatus(Status.blank);
    setFetchVCStatus(Status.blank);
    setResendTokenStatus(Status.inProgress);
  };

  const handleNewPasswordSubmitClick = (values: any) => {
    setState({
      ...state,
      newPassword: values.newPassword,
    });
    setFetchVCStatus(Status.inProgress);
  };

  const renderPhoneNumberPanel = () => (
    <EvlBox pt={1.4}>
      <EvlContainer maxWidth="sm">
        <EvlGrid container justify="center" alignItems="center">
          <EvlGrid item xs={12}>
            <EvlTypography color="textSecondary" align="center" variant="body2" className={classes.subtitle}>
              {translate('forgotPassword.phoneNumberPanel.subtitle')}
            </EvlTypography>
          </EvlGrid>
          <EvlGrid item xs={12}>
            <Formik
              validateOnBlur={true}
              validateOnChange={true}
              validateOnMount={true}
              validationSchema={NewEmailValidations(translate)}
              onSubmit={handleForgotPasswordClick}
              initialValues={{
                email: '',
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <EvlGrid container justify="center" alignItems="center" spacing={3}>
                    <EvlGrid item xs={12}>
                      <ValTextField
                        id="textfield-forgot-email"
                        name="email"
                        value={state.email}
                        label={translate('field.label.email')}
                        autoCapitalize="off"
                        onKeyDown={onEnterFocusNext}
                        error={errors.email !== undefined && touched.email === true}
                      />
                    </EvlGrid>
                    <EvlGrid item xs={12}>
                      <EvlBox className={classes.phoneFooterActions}>
                        <ButtonBar
                          buttonList={[
                            {
                              buttonId: 'forgot-password-submit-phone-number-button',
                              buttonType: 'submit' as 'submit',
                              buttonInProgress: fetchFPStatus === Status.inProgress,
                              disableButton: false,
                              buttonLabel: translate('button.sendCode'),
                              isFormButton: true,
                            },
                            {
                              buttonId: 'forgot-password-cancel-phone-number-button',
                              className: classes.cancelButton,
                              buttonType: 'button',
                              buttonLabel: translate('button.cancel'),
                              isFormButton: false,
                              href: '/login?panel=normalLoginPanel',
                            },
                          ]}
                        />
                      </EvlBox>
                    </EvlGrid>
                  </EvlGrid>
                </Form>
              )}
            </Formik>
          </EvlGrid>
        </EvlGrid>
      </EvlContainer>
    </EvlBox>
  );

  const renderNewPasswordPanel = () => (
    <EvlBox mt={2}>
      <EvlContainer maxWidth="sm">
        <EvlGrid container justify="center" alignItems="center">
          <EvlGrid item xs={12}>
            <EvlBox textAlign="center">
              <EvlTypography className={classes.enterYourOtp} color="textPrimary" variant="body1">
                {translate('account.changePhoneNumber.code')}
              </EvlTypography>
              <EvlTypography
                color="textSecondary"
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: translate('forgotPassword.phoneNumberPanel.description', { email: state.email }),
                }}
              />
            </EvlBox>
          </EvlGrid>
          <EvlGrid item xs={12} style={{ marginTop: '35px' }}>
            <VerificationToken
              hideCaption
              codeValues={codeValues}
              setCodeValues={setCodeValues}
              inputType="numbers"
              error={codeError}
            />
          </EvlGrid>
          <EvlGrid item xs={12} style={{ marginTop: '5px' }}>
            {resendCodeButton()}
          </EvlGrid>
          <EvlGrid item xs={12}>
            <Formik
              validateOnBlur={true}
              validateOnChange={true}
              validateOnMount={true}
              onSubmit={handleNewPasswordSubmitClick}
              initialValues={{
                newPassword: '',
              }}
            >
              {({ errors, touched, isValidating }) => (
                <Form>
                  {isValidating && validateCode()}
                  <EvlGrid container justify="center" alignItems="center" style={{ marginTop: '40px' }}>
                    <EvlGrid item xs={12}>
                      <ValTextField
                        id="textfield-forgot-credentials-password"
                        label={translate('button.newPassword')}
                        type="password"
                        name="newPassword"
                        style={{ margin: 0 }}
                        error={errors.newPassword !== undefined && touched.newPassword === true}
                        autoComplete="off"
                        isPasswordField
                        validate={(v: string) => validatePassword(translate, v)}
                        formHelperTextComponent={(meta: any) => <PasswordValidationCheckList {...meta} />}
                      />
                    </EvlGrid>
                    <EvlGrid item xs={12} className={classes.footerActions}>
                      <ButtonBar
                        buttonList={[
                          {
                            buttonId: 'forgot-password-submit-new-password-button',
                            buttonType: 'submit' as 'submit',
                            buttonStyle: 'primary',
                            buttonInProgress: fetchVCStatus === Status.inProgress,
                            buttonLabel: translate('button.submit'),
                            isFormButton: true,
                          },
                          {
                            buttonId: 'forgot-password-cancel-phone-number-button',
                            className: classes.cancelButton,
                            buttonType: 'button',
                            buttonLabel: translate('button.cancel'),
                            isFormButton: false,
                            href: '/login?panel=normalLoginPanel',
                          },
                        ]}
                      />
                    </EvlGrid>
                  </EvlGrid>
                </Form>
              )}
            </Formik>
          </EvlGrid>
        </EvlGrid>
      </EvlContainer>
    </EvlBox>
  );

  const renderSuccess = () => (
    <EvlBox pt={3.8}>
      <EvlContainer maxWidth="sm">
        <EvlGrid container item xs={12} justify="center" alignItems="center">
          <EvlGrid item xs={12} justify="center" alignItems="center">
            <SvgIcon width={61} height={60} viewBox="0 0 61 60" className={classes.successIcon}>
              <path d="M55.5 27.7001V30.0001C55.4969 35.3912 53.7513 40.6368 50.5233 44.9547C47.2954 49.2726 42.7582 52.4313 37.5884 53.9599C32.4186 55.4885 26.8931 55.3049 21.8362 53.4366C16.7792 51.5683 12.4616 48.1154 9.5274 43.5928C6.59317 39.0702 5.19948 33.7203 5.55419 28.3409C5.90891 22.9616 7.99302 17.841 11.4957 13.7428C14.9984 9.6447 19.732 6.78861 24.9905 5.60051C30.249 4.41242 35.7507 4.95599 40.675 7.15015" />
              <path d="M55.5 10L30.5 35.025L23 27.525" />
            </SvgIcon>
          </EvlGrid>

          <EvlGrid item xs={12}>
            <EvlTypography
              color="primary"
              align="center"
              classes={{ body1: classes.successUpdateLabel }}
              variant="body1"
            >
              {translate('forgotPassword.successPanel.title')}
            </EvlTypography>
          </EvlGrid>
          <EvlGrid item xs={12} style={{ paddingTop: '12px' }}>
            <EvlTypography color="secondary" align="center" variant="body2">
              {translate('forgotPassword.successPanel.description')}
            </EvlTypography>
          </EvlGrid>
          <EvlGrid item xs={12} style={{ paddingTop: '32px' }}>
            <ButtonBar
              buttonList={[
                {
                  buttonId: 'forgot-redirect-login',
                  buttonType: 'button',
                  buttonStyle: 'primary',
                  buttonLabel: translate('button.login'),
                  href: '/login?panel=normalLoginPanel',
                },
              ]}
            />
          </EvlGrid>
        </EvlGrid>
      </EvlContainer>
    </EvlBox>
  );

  const resendCodeButton = () => (
    <React.Fragment>
      {resendTokenStatus !== Status.inProgress && (
        <EvlTypography variant="body2" align="center">
          {`${translate('forgotPassword.text.codeNotRecieved')}  `}
          <EvlLink onClick={handleResendTokenClick} className={classes.resendText}>
            {translate('forgotPassword.link.resendCode')}
          </EvlLink>
        </EvlTypography>
      )}
    </React.Fragment>
  );

  const topBarTitle =
    fetchVCStatus === Status.success
      ? translate('forgotPassword.title.success')
      : visiblePanel === 'numberPanel'
      ? translate('forgotPassword.title.forgot')
      : visiblePanel === 'newPasswordPanel'
      ? translate('forgotPassword.title.newPassword')
      : '';

  const handleClose = () => {
    setOpen(false);
  };

  const handlePanelBack = () => {
    if (visiblePanel === 'newPasswordPanel') {
      setVisiblePanel('numberPanel');
      setCodeValues(initialCodeValues);
    } else {
      router.push('/login?panel=normalLoginPanel');
    }
  };

  return (
    <>
      <TopBar
        disableBackButton={fetchVCStatus === Status.success}
        disableMenuDrawer
        title={topBarTitle}
        onBack={handlePanelBack}
      >
        {fetchVCStatus !== Status.success && (
          <React.Fragment>
            {visiblePanel === 'numberPanel' && renderPhoneNumberPanel()}
            {visiblePanel === 'newPasswordPanel' && renderNewPasswordPanel()}
          </React.Fragment>
        )}
        {fetchVCStatus === Status.success && renderSuccess()}
      </TopBar>
      <div className={classes.snackbar}>
        <EvlSnackbar open={open} success={false} message={failureMessage} handleClose={handleClose} duration={5000} />
      </div>
    </>
  );
};

export default ForgotPasswordPanel;
