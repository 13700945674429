import React from 'react';
import {
  Button,
  EvlBox,
  EvlCarousel,
  EvlContainer,
  EvlGrid,
  EvlImageWithLoader,
  EvlTypography,
} from 'evl-ui-components';
import { useStyles } from '.';
import { useFormatMessage } from 'evl-ui-lib';
import getConfig from 'next/config';
import Footer from '../../footer';

const { publicRuntimeConfig } = getConfig();

interface IntroPanelProps {
  handleLoginButtonClick: () => void;
  handleRegisterButtonClick: () => void;
  redirectPage: string;
}

const IntroPanel = ({ handleLoginButtonClick, handleRegisterButtonClick, redirectPage }: IntroPanelProps) => {
  const classes = useStyles({ redirectPage });
  const translate = useFormatMessage();

  const [showText, setShowText] = React.useState<boolean>(false);

  const render = () => {
    setShowText(true);
  };

  const LoginPanel = () => (
    <>
      <div className={classes.container}>
        <div className={classes.center}>
          <img src="/static/images/identifye-logo.svg" alt="Identify" className={classes.logo} />
          <EvlBox id="intro-page" className={classes.authContainer}>
            <EvlContainer maxWidth="sm" className={classes.whiteContainer}>
              <EvlGrid container justify="center" alignItems="center" spacing={1}>
                <EvlGrid item xs={12} className={classes.indicators}>
                  <EvlCarousel transitionDuration={500} indicators={true} arrows={false}>
                    <EvlGrid item xs={12}>
                      <EvlGrid container justify="center" alignItems="center" spacing={3}>
                        <EvlGrid item lg={9} md={9} sm={9} xs={12}>
                          <EvlBox textAlign="center">
                            <EvlImageWithLoader
                              height="240px"
                              loadingHeight="437px"
                              url={'/static/images/onboarding-1.svg'}
                              draggable={false}
                              onLoadCallback={render}
                            />
                            {showText && (
                              <>
                                <EvlTypography color="textPrimary" variant="h5" className={classes.panelHeader}>
                                  {translate('onboarding.page.tap.title')}
                                </EvlTypography>
                                <EvlTypography
                                  color="textSecondary"
                                  variant="body2"
                                  className={classes.panelText}
                                  dangerouslySetInnerHTML={{ __html: translate('onboarding.page.tap.subtitle') }}
                                />
                              </>
                            )}
                          </EvlBox>
                        </EvlGrid>
                      </EvlGrid>
                    </EvlGrid>
                    <EvlGrid item xs={12}>
                      <EvlGrid container justify="center" alignItems="center" spacing={3}>
                        <EvlGrid item lg={9} md={9} sm={9} xs={12}>
                          <EvlBox textAlign="center">
                            <EvlImageWithLoader
                              height="240px"
                              loadingHeight="437px"
                              url={'/static/images/onboarding-2.svg'}
                              draggable={false}
                              onLoadCallback={render}
                            />
                            {showText && (
                              <>
                                <EvlTypography color="textPrimary" variant="h5" className={classes.panelHeader}>
                                  {translate('onboarding.page.add.title')}
                                </EvlTypography>
                                <EvlTypography color="textSecondary" variant="body2" className={classes.panelText}>
                                  {translate('onboarding.page.add.subtitle')}
                                </EvlTypography>
                              </>
                            )}
                          </EvlBox>
                        </EvlGrid>
                      </EvlGrid>
                    </EvlGrid>
                    <EvlGrid item xs={12}>
                      <EvlGrid container justify="center" alignItems="center" spacing={3}>
                        <EvlGrid item lg={9} md={9} sm={9} xs={12}>
                          <EvlBox textAlign="center" className={classes.ownership}>
                            <EvlImageWithLoader
                              height="240px"
                              loadingHeight="437px"
                              url={'/static/images/onboarding-3.svg'}
                              draggable={false}
                              onLoadCallback={render}
                            />
                            {showText && (
                              <>
                                <EvlTypography color="textPrimary" variant="h5" className={classes.panelHeader}>
                                  {translate('onboarding.page.transfer.title')}
                                </EvlTypography>
                                <EvlTypography color="textSecondary" variant="body2" className={classes.panelText}>
                                  {translate('onboarding.page.transfer.subtitle')}
                                </EvlTypography>
                              </>
                            )}
                          </EvlBox>
                        </EvlGrid>
                      </EvlGrid>
                    </EvlGrid>
                  </EvlCarousel>
                </EvlGrid>
                <EvlGrid item xs={12}>
                  <EvlBox textAlign="center">
                    <Button
                      color="secondary"
                      className={classes.btnAuth}
                      onClick={handleLoginButtonClick}
                      size="large"
                      fullWidth
                    >
                      {translate('button.login')}
                    </Button>
                    <Button
                      color="primary"
                      className={classes.btnSignup}
                      onClick={handleRegisterButtonClick}
                      size="large"
                      fullWidth
                    >
                      {translate('button.register')}
                    </Button>
                  </EvlBox>
                </EvlGrid>
              </EvlGrid>
            </EvlContainer>
          </EvlBox>
        </div>
        <Footer variant="light" bottom={0} page="introPanel" />
      </div>
    </>
  );

  return <React.Fragment>{publicRuntimeConfig.INTRO_PANEL_TYPE === 'type1' && LoginPanel()}</React.Fragment>;
};

export default IntroPanel;
