import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
// import { baseHeader } from '../jss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      // ...baseHeader(theme).default,
      width: '100%',
      textAlign: 'left',
      padding: '0 16px',
    },
    description: {
      color: theme.palette.primary.main,
      marginTop: `${theme.spacing(1.4)}px !important`,
    },
    fullWidthDivider: {
      left: '-16px',
      width: 'calc(100% + 32px)',
      position: 'relative',
      maxWidth: theme.breakpoints.values.md,
      marginTop: theme.spacing(2.25),
      borderBottom: (props: { [key: string]: any }) =>
        props.hideBottomBorder ? undefined : `0.5px solid ${theme.palette.primary.main}`,
    },
  }),
);

interface TitlePanelProps {
  title: string;
  description?: string;
  hideBottomBorder?: boolean;
}

const TitlePanel = ({ title, description, hideBottomBorder }: TitlePanelProps) => {
  const classes = useStyles({ hideBottomBorder });

  return (
    <div className={classes.titleContainer}>
      <Typography variant="h1"> {title} </Typography>
      {description && (
        <Typography variant="body1" className={classes.description}>
          {' '}
          {description}{' '}
        </Typography>
      )}
      <div className={classes.fullWidthDivider}></div>
    </div>
  );
};

export default TitlePanel;
