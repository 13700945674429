import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { updateUserAttrs, usernameAvailable, getAttributeVerificationCode, verifyAttribute } from '../../api/user';
import { PhoneNumberField, ButtonBar, ErrorMessage } from '../../components';
import { baseTextField, inputField, inputLabel, submitButton } from '../../jss';
import { CountryCodeNew, Status, FromChangePasswordPanel } from '../../types';
import { phoneNumberBuilder } from '../../utils';
import { CodePanel } from '../login';
import { Formik, Form } from 'formik';
import { NewPhoneNumberValidations } from '../../validations';
import { useFormatMessage } from 'evl-ui-lib';
import { Button, EvlContainer } from 'evl-ui-components';
import Router from 'next/router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      width: '100%',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
    },
    topSectionContainer: {
      width: '100%',
      marginBottom: '60px',
    },
    step: {
      color: 'white',
      width: '100%',
      textAlign: 'left',
      position: 'relative',
      marginBottom: theme.spacing(1.75),

      '& h3': {
        fontSize: '1.25rem',
      },
    },
    verifyPhoneNoContainer: {
      width: '100%',
      height: '100%',

      '& input': {
        marginBottom: theme.spacing(3.5),
      },
    },
    textFields: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    textField: baseTextField(theme).default,
    textLabel: inputLabel(theme).default,
    textLabelFocused: inputLabel(theme).default,
    textInput: inputField(theme).default,
    changePhoneNumberButton: {
      marginTop: '24px',
    },
    loginContainer: {
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      margin: 0,
      fontSize: '1.18rem',
      width: '100%',
      padding: '12px 0px',

      '& p': {
        margin: 0,
      },

      '& a': {
        color: 'white',
        fontWeight: 700,
        textDecoration: 'underline',
      },
    },
    loginButton: {
      padding: 0,
    },
    buttonRoot: {
      margin: 0,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    buttonLabel: {},
    submitButton: {},
    cancelButton: {
      marginTop: '24px',
    },
    nextButton: {
      marginTop: '24px',
    },
    loadingButton: {
      marginTop: '24px',
    },
    disabledButton: submitButton(theme).disabled,
    failureContainer: {
      color: '#FF3D3D',
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(3.25),
      fontSize: '1.18rem',
      width: '100%',
      textAlign: 'left',

      '& p': {
        margin: 0,
      },
    },
    titleContainer: {
      marginBottom: theme.spacing(1.75),
    },
    formActionsContainer: {
      display: 'flex',
      justifyContent: 'space-between',

      '& button': {
        width: '48%',
      },
    },
    selectControl: {
      borderRadius: '0px',
    },
    phoneNumberField: {
      width: '70%',
      marginLeft: 'auto',
    },
    phoneFieldLabel: {
      display: 'none',
    },
    backButton: {
      color: 'white',
      position: 'absolute',
      left: '0',
      justifyContent: 'left',
      paddingLeft: '0',

      '& img': {
        transform: 'rotate(90deg)',
        width: '18px',
      },
    },
    tokenResultContainer: {
      textAlign: 'center',
      marginTop: '84px',

      '& img': {
        marginBottom: '20px',
      },

      '& p': {
        textAlign: 'left',
      },
    },
    successMessage: {
      fontSize: '1.14rem',
      lineHeight: 'initial',
      color: theme.palette.success.main,
    },
    contactButton: {
      marginTop: theme.spacing(4.25),
    },
    returnButton: {
      marginTop: theme.spacing(4.25),
    },
    form: {
      width: '100%',
    },
    buttonContainer: {
      left: 0,
      right: 0,
      bottom: '20px',
      margin: '0px 20px',
      position: 'absolute',
    },
    cancel: {
      width: '100%',
      height: '40px',
      marginTop: '12px',
      fontFamily: 'Lato',
      fontSize: '14px',
      borderRadius: '4px',
      backgroundColor: '#E7EBED',
      textTransform: 'none',
      color: '#062734',
    },
    codeContainer: {
      position: 'relative',
      minHeight: 'calc(100vh - 90px)',
    },
    submit: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '20px',
      margin: '0px 20px',
    },
  }),
);

interface ChangePhoneNumberProps {
  closePanel(): void;
  currentPhoneNumber: string;
  from: FromChangePasswordPanel;
  successCallback?: () => void;
  removeTopPadding?: boolean;
  refetchPersonalDetailsData?: () => void;
}

const ChangePhoneNumber: React.SFC<ChangePhoneNumberProps> = ({
  closePanel,
  currentPhoneNumber,
  from,
  successCallback,
  removeTopPadding,
  refetchPersonalDetailsData,
}) => {
  const classes = useStyles({ removeTopPadding });
  const translate = useFormatMessage();

  const initialCountryCode: CountryCodeNew = {
    label: `${String.fromCodePoint(0x1f1ec, 0x1f1e7)} United Kingdom (+44)`,
    shortLabel: `${String.fromCodePoint(0x1f1ec, 0x1f1e7)} UK`,
    flagCodePoint: String.fromCodePoint(0x1f1ec, 0x1f1e7),
    country: 'United Kingdom',
    countryAbbr: 'UK',
    countryAbbr3: 'GBR',
    value: '+44',
  };

  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [countryCode, setCountryCode] = React.useState<CountryCodeNew>(initialCountryCode);
  const [showCodePanel, setShowCodePanel] = React.useState<'verification' | 'confirmation' | undefined>(undefined);
  const [updateStatus, setUpdateStatus] = React.useState<Status>(Status.blank);
  const [submitCodeStatus, setSubmitCodeStatus] = React.useState<Status>(Status.blank);
  const initialCodeValues: string[] = ['', '', '', '', '', ''];
  const [codeValues, setCodeValues] = React.useState<String[]>(initialCodeValues);
  const [resendCodeStatus, setResendCodeStatus] = React.useState<Status>(Status.blank);
  const [codeVerifiedStatus, setCodeVerifiedStatus] = React.useState<Status>(Status.blank);
  const [failureMessage, setFailureMessage] = React.useState<string>('');

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    const submitCode = async () => {
      const verificationCode = codeValues.join('');
      if (submitCodeStatus !== Status.inProgress) return;
      try {
        await verifyAttribute({
          code: verificationCode,
          attributeName: 'phone_number',
        });
        setSubmitCodeStatus(Status.success);
        setCodeVerifiedStatus(Status.success);
        setShowCodePanel(undefined);
      } catch (err) {
        // setFailureMessage(err.message);
        setFailureMessage(translate('account.changePhoneNumber.error.message'));
        setSubmitCodeStatus(Status.failure);
      }
    };
    submitCode();
  }, [submitCodeStatus]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (resendCodeStatus === Status.inProgress) {
        try {
          await getAttributeVerificationCode({ attributeName: 'phone_number' });
          setResendCodeStatus(Status.success);
        } catch {
          setResendCodeStatus(Status.failure);
        }
      }
    };
    fetchData();
  }, [resendCodeStatus]);

  const updatePhone = async (fullPhoneNumber: string) => {
    try {
      await usernameAvailable(fullPhoneNumber);
      await updateUserAttrs({
        userAttributes: [
          {
            Name: 'phone_number',
            Value: fullPhoneNumber,
          },
        ],
      });
      return true;
    } catch (err) {
      throw err;
    }
  };

  const handleSaveClick = async (values: any, formikBag: any) => {
    const fullPhoneNumber = phoneNumberBuilder({
      countryCodeValue: countryCode.value,
      phoneNumber: values.phoneNumber,
    });
    if (fullPhoneNumber === currentPhoneNumber) {
      formikBag.setFieldError('phoneNumber', 'Same as current number');
      setUpdateStatus(Status.failure);
      return;
    }
    updatePhone(fullPhoneNumber)
      .then(() => {
        setPhoneNumber(values.phoneNumber);
        setShowCodePanel('verification');
        setUpdateStatus(Status.success);
        refetchPersonalDetailsData && refetchPersonalDetailsData();
      })
      .catch(err => {
        if (err.message === 'username already taken') {
          formikBag.setFieldError('phoneNumber', 'Phone number in use');
        } else {
          formikBag.setFieldError('phoneNumber', err.message);
        }
        setUpdateStatus(Status.failure);
      });
  };

  const handleCodePanelNextClick = () => {
    setSubmitCodeStatus(Status.inProgress);
  };

  const handleCountryCodeInput = (val: any) => {
    setCountryCode({ ...val });
  };

  const handleResendClick = () => {
    setResendCodeStatus(Status.inProgress);
  };

  const renderChangePhoneNumber = () => (
    <EvlContainer maxWidth="sm" className={classes.codeContainer}>
      <Formik
        validateOnBlur={true}
        validateOnChange={true}
        validateOnMount={true}
        validationSchema={NewPhoneNumberValidations(currentPhoneNumber, translate)}
        onSubmit={handleSaveClick}
        initialValues={{ code: initialCountryCode, phoneNumber }}
      >
        {({ isValid, setFieldValue }) => (
          <Form className={classes.form}>
            <PhoneNumberField
              id="textfield-account-change-phone"
              countryCode={countryCode}
              handleCountryCodeInput={handleCountryCodeInput}
              phoneNumber={phoneNumber}
              setFieldValue={setFieldValue}
            />
            <div className={classes.buttonContainer}>
              <ButtonBar
                buttonList={[
                  {
                    buttonId: 'change-phone-number-submit-button',
                    buttonType: 'submit' as 'submit',
                    buttonStyle: 'primary',
                    buttonLabel: translate('button.sendCode'),
                    disableButton: !isValid,
                    isFormButton: true,
                  },
                ]}
              />
              <Button className={classes.cancel} onClick={() => Router.back()}>
                {translate('button.cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </EvlContainer>
  );

  const renderCodeSuccess = () => (
    <React.Fragment>
      <Typography className={classes.successMessage}>
        {translate('account.changePhoneNumber.success.message')}
      </Typography>
      {from === FromChangePasswordPanel.personalDetails && (
        <ButtonBar
          buttonList={[
            {
              buttonId: 'account-return-to-personal-details',
              buttonType: 'submit' as 'submit',
              buttonStyle: 'primary',
              buttonLabel: translate('account.changePhoneNumber.success.return'),
              handleButtonClick: closePanel,
            },
          ]}
        />
      )}
      {from === FromChangePasswordPanel.normalLogin && (
        <ButtonBar
          buttonList={[
            {
              buttonId: 'account-return-to-home-button',
              buttonType: 'submit' as 'submit',
              buttonStyle: 'primary',
              buttonLabel: translate('login.changePhoneNumber.success.return'),
              handleButtonClick: successCallback && successCallback,
            },
          ]}
        />
      )}
    </React.Fragment>
  );

  const renderCodePanel = () => (
    <EvlContainer maxWidth="sm" className={classes.codeContainer}>
      <CodePanel
        codeValues={codeValues}
        setCodeValues={setCodeValues}
        resendCodeStatus={resendCodeStatus}
        handleResendClick={handleResendClick}
        fullNumber={phoneNumberBuilder({
          countryCodeValue: countryCode.value,
          phoneNumber: phoneNumber,
        })}
      >
        {submitCodeStatus === Status.failure && <ErrorMessage message={failureMessage} />}
        <div className={classes.submit}>
          <ButtonBar
            buttonList={[
              {
                buttonId: 'account-confirm-new-phone-number-button',
                buttonType: 'submit' as 'submit',
                buttonStyle: 'primary',
                buttonLabel: translate('button.verify'),
                handleButtonClick: handleCodePanelNextClick,
              },
            ]}
          />
          <Button className={classes.cancel} onClick={() => setShowCodePanel(undefined)}>
            {translate('button.cancel')}
          </Button>
        </div>
      </CodePanel>
    </EvlContainer>
  );

  return (
    <React.Fragment>
      {!showCodePanel && updateStatus !== Status.success && renderChangePhoneNumber()}
      {showCodePanel && renderCodePanel()}
      {!showCodePanel && codeVerifiedStatus === Status.success && renderCodeSuccess()}
    </React.Fragment>
  );
};

export default ChangePhoneNumber;
