import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    promptIcon: {
      marginRight: '5px',
    },
    invalidText: {
      color: '#636363',
      fontSize: '12px',
      fontFamily: 'Gotham Book',
    },
    validText: {
      color: '#65B365',
      fontSize: '12px',
      fontFamily: 'Gotham Book',
    },
    prompt: {
      color: '#616060',
      fontSize: '12px',
      fontFamily: 'Gotham Book',
      marginTop: '3px',
    },
    validations: {
      marginTop: '3px',
    },
  }),
);

export default useStyles;
