import * as Yup from 'yup';
import { phoneNumberBuilder } from '../utils';
import { FormatMessage } from 'evl-ui-lib/lib/utils/i18n/formatMessage';
// @ts-ignore
import validator from 'validator';
import { differenceInYears } from 'date-fns';

export const passwordValidationSchema = (translate: FormatMessage) =>
  Yup.string()
    .matches(/[ `!@#£€$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, translate('password.symbol.error'))
    .matches(/[A-Z]/, translate('password.uppercase.error'))
    .matches(/[a-z]/, translate('password.lowercase.error'))
    .matches(/[0-9]/, translate('password.number.error'))
    .min(8, translate('password.minlength.error'));

export const NormalLoginValidations = (translate: FormatMessage) =>
  Yup.object().shape({
    username: Yup.string().required(translate('email.empty.error')),
    password: Yup.string().required(translate('password.required.error')),
  });

export const NewEmailValidations = (translate: FormatMessage) =>
  Yup.object().shape({
    email: Yup.string()
      .required(translate('email.empty.error'))
      .email(translate('email.valid.error')),
  });

export const PasswordValidations = (translate: FormatMessage) =>
  Yup.object().shape({
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], translate('password.match.error'))
      .required(translate('password.confirmation.error')),
  });

export const ClashUsernameValidations = (translate: FormatMessage) =>
  Yup.object().shape({
    username: Yup.string()
      .required('Required')
      .min(2, translate('username.length.error'))
      .max(64, translate('username.length.error'))
      .matches(/^\S*$/, translate('username.emptyspace.error'))
      .matches(/^([a-zA-Z0-9\-\_]*)$/g, translate('username.invalid.error')),
  });

export const ChangePasswordValidations = (translate: FormatMessage) =>
  Yup.object().shape({
    oldPassword: Yup.string().required(translate('password.required.error')),
  });

export const UserDetailsValidations = (translate: FormatMessage) =>
  Yup.object().shape({
    username: Yup.string()
      .required(translate('username.empty.error'))
      .matches(/^\S*$/, translate('username.emptyspace.error'))
      .matches(/^([a-zA-Z0-9\-\_]*)$/g, translate('username.invalid.error')),
    email: Yup.string()
      .required(translate('email.empty.error'))
      .email(translate('email.valid.error')),
  });

export const BirthdayValidations = (translate: FormatMessage) =>
  Yup.object().shape({
    birthday: Yup.string().test('checkDateValidation', (value, { createError }) => {
      if (!value) return true;
      const date = value.split('/');
      let emptyCount = 0;
      date.forEach((val: string) => {
        (val === '' || val === 'null') && emptyCount++;
      });
      if (emptyCount > 0 && emptyCount < 3) {
        return createError({
          message: translate('birthday.invalid.error'),
        });
      }
      if (emptyCount === 3) {
        return createError({
          message: translate('birthday.empty.error'),
        });
      }
      const birthday = new Date(Date.UTC(parseInt(date[2]), parseInt(date[1]) - 1, parseInt(date[0]), 0, 0, 0));
      // find date out of calender range
      if (
        birthday.getUTCDate() !== parseInt(date[0]) ||
        birthday.getUTCMonth() !== parseInt(date[1]) - 1 ||
        birthday.getUTCFullYear() !== parseInt(date[2])
      ) {
        return createError({
          message: translate('birthday.invalid.error'),
        });
      }
      if (differenceInYears(new Date(), birthday) < 16) {
        return createError({
          message: translate('birthday.age.error'),
        });
      }
      // find date after now
      return true;
    }),
  });

export const CityValidations = (translate: FormatMessage) =>
  Yup.object().shape({
    city: Yup.string().required(translate('city.required.error')),
  });

export const PhoneNumberValidations = (translate: FormatMessage) =>
  Yup.object().shape({
    code: Yup.object()
      .nullable()
      .required('Required'),
    phoneNumber: Yup.string()
      .required('Required')
      .min(8, translate('number.minlength.error'))
      .max(15, translate('number.maxlength.error'))
      .matches(/^([0-9]*)$/g, translate('number.onlydigits.error')),
  });

export const NewPhoneNumberValidations = (currentPhoneNumber: string, translate: FormatMessage) =>
  Yup.object().shape({
    code: Yup.object()
      .nullable()
      .required('Required'),
    phoneNumber: Yup.string()
      .required('Required')
      .min(8, translate('number.minlength.error'))
      .max(15, translate('number.maxlength.error'))
      .matches(/^([0-9]*)$/g, translate('number.onlydigits.error'))
      .test('CountryCodeRequired', translate('countrycode.empty.error'), function(_value) {
        const countryCode = this.parent.code;
        return !!countryCode;
      })
      .test('compareWithCurrentNumber', translate('number.same.error'), function(value) {
        if (!value) return false;
        const countryCode = this.parent.code;
        if (!countryCode) return false;
        const countryCodeValue = countryCode.value;
        const newPhoneNumber = phoneNumberBuilder({
          countryCodeValue: countryCodeValue,
          phoneNumber: value,
        });
        if (newPhoneNumber === currentPhoneNumber) {
          return false;
        } else {
          return true;
        }
      }),
  });

export const PersonalDetailsValidations = (translate: FormatMessage) =>
  Yup.object().shape({
    preferredUsername: Yup.string()
      .required(translate('username.empty.error'))
      .matches(/^\S*$/, translate('username.emptyspace.error'))
      .matches(/^([a-zA-Z0-9\-\_]*)$/g, translate('username.invalid.error')),
    city: Yup.string().required(translate('city.required.error')),
    email: Yup.string()
      .required(translate('email.empty.error'))
      .email(translate('email.valid.error')),
    birthday: Yup.string().test('checkDateValidation', (value, { createError }) => {
      if (!value) return true;
      const date = value.split('/');
      let emptyCount = 0;
      date.forEach((val: string) => {
        (val === '' || val === 'null') && emptyCount++;
      });
      if (emptyCount > 0 && emptyCount < 3) {
        return createError({
          message: translate('birthday.invalid.error'),
        });
      }
      if (emptyCount === 3) {
        return createError({
          message: translate('birthday.empty.error'),
        });
      }
      const birthday = new Date(Date.UTC(parseInt(date[2]), parseInt(date[1]) - 1, parseInt(date[0]), 0, 0, 0));
      // find date out of calender range
      if (
        birthday.getUTCDate() !== parseInt(date[0]) ||
        birthday.getUTCMonth() !== parseInt(date[1]) - 1 ||
        birthday.getUTCFullYear() !== parseInt(date[2])
      ) {
        return createError({
          message: translate('birthday.invalid.error'),
        });
      }
      if (differenceInYears(new Date(), birthday) < 16) {
        return createError({
          message: translate('birthday.age.error'),
        });
      }
      // find date after now
      return true;
    }),
  });

export const DeleteAccountConfirmValidations = (translate: FormatMessage) =>
  Yup.object().shape({
    deleteConfirm: Yup.string()
      .required(translate('account.delete.confirm.incorrectInput'))
      .test('checkDeleteInput', translate('account.delete.confirm.incorrectInput'), value => value === 'DELETE'),
  });
