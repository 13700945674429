import { createStyles, makeStyles, TextField } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { EvlBox, EvlTypography } from 'evl-ui-components';
import React, { Dispatch, RefObject, SetStateAction, useRef } from 'react';
import { baseTextField } from '../jss';
import { useFormatMessage } from 'evl-ui-lib';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tokenInputField: (props: any) => ({
      ...baseTextField(theme).default,
      margin: theme.spacing(0.6),
      marginBottom: theme.spacing(1),
      width: '39px',
      backgroundColor: '#FFFFFF',
      borderWidth: theme.spacing(0.1),
      borderStyle: 'solid',
      borderColor: props.borderColor || theme.palette.grey.A100,
      borderRadius: theme.shape.borderRadius * 4,
      minHeight: '48px',

      '& input': {
        ...baseTextField(theme).input,
        fontFamily: 'Gotham Book',
        textAlign: 'center',
        fontSize: '24px',
        paddingTop: '7px',
      },
    }),
    cover: {
      position: 'absolute',
      height: theme.spacing(7.5),
      width: `min(calc(100% - ${theme.spacing(5.75)}px), ${theme.spacing(51)}px)`,
      zIndex: 10000,
      background: 'none',
    },
    noCover: {
      display: 'none',
    },
    codeSeparator: {
      backgroundColor: theme.palette.background.default,
      height: 1.8,
      width: 10,
      margin: '0 6px',
    },
    title: {
      fontFamily: 'Lato',
      fontSize: '20px',
      color: '#000000',
      marginTop: '24px',
    },
    subtitle: {
      fontFamily: 'Lato',
      fontSize: '14px',
      color: '#636363',
      marginBottom: '34px',
      marginTop: '10px',
      lineHeight: '17px',
    },
    errorWrapper: {
      width: '294px',
      margin: '0 auto',
    },
    '@media (max-width: 345px)': {
      errorWrapper: {
        paddingLeft: '6px',
      },
    },
    errorText: {
      marginTop: '-10px',
      marginBottom: '4px',
      textAlign: 'left',
      color: '#B00020',
      fontSize: '12px',
    },
  }),
);

interface VerificationTokenProps {
  codeValues: String[];
  setCodeValues: Dispatch<SetStateAction<String[]>>;
  inputType?: 'numbers' | 'letters';
  fullNumber?: string;
  hideCaption?: boolean;
  error?: boolean;
  hideText?: boolean;
  settings?: boolean;
}

const VerificationToken = ({
  codeValues,
  setCodeValues,
  inputType,
  fullNumber,
  hideCaption,
  error,
  hideText,
  settings,
}: VerificationTokenProps) => {
  const [cover, setCover] = React.useState<boolean>(true);
  const [showError, setShowError] = React.useState<boolean>(false);

  const styleProps = showError && { borderColor: '#B00020' };
  const classes = useStyles(styleProps);

  const refs: RefObject<HTMLInputElement>[] = codeValues.map(() => useRef<HTMLInputElement>(null));

  React.useEffect(() => {
    error !== undefined && setShowError(error);
  }, [error]);

  React.useEffect(() => {
    if (codeValues.join('').length === codeValues.length) {
      setShowError(false);
    } else {
      error && setShowError(true);
    }
  }, [codeValues]);

  const handleCodeValueChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (inputType === 'numbers' && isNaN(event.target.value as any)) {
      return;
    }
    const value = event.target.value;
    let code = codeValues;
    code[index] = value;

    // Checks if the first input field doesn't include digits as that would come from the sms auto-fill
    if (code[index].length > 1 && code[0].length !== codeValues.length) {
      const lastCharIdx = code[index].length - 1;
      const adjustedValue = code[index].charAt(lastCharIdx);
      code[index] = adjustedValue;
    }

    if (value.length > 0) {
      if (index + 1 < refs.length) {
        if (refs[index + 1].current) {
          (refs[index + 1] as any).current.focus();
        }
      }
    }

    // If the code has been auto-filled from the sms
    if (code[0].length === codeValues.length) {
      const autoFillCode = code[0].split('');
      code = autoFillCode;
      (refs[codeValues.length - 1] as any).current.focus();
    }

    setCodeValues([...code]);
  };

  const handleKeyDown = (index: number) => (event: React.KeyboardEvent) => {
    if (index !== 0 && codeValues[index].length === 0 && event.key === 'Backspace') {
      if (refs[index - 1].current) {
        (refs[index - 1] as any).current.focus();
      }
    }
  };

  const handleCodeValueBlur = (idx: number) => {
    const code = codeValues;
    if (code[idx] === '') {
      code[idx] = '';
      setCodeValues([...code]);
    }
  };

  const handleCodeValueFocus = (idx: number) => {
    const code = codeValues;
    if (code[idx] === '') {
      code[idx] = '';
      setCodeValues([...code]);
    }
  };

  const handleCoverClick = () => {
    if (refs[0] && refs[0].current) {
      refs[0].current.focus();
    }
    setCover(false);
  };

  const translate = useFormatMessage();

  return (
    <React.Fragment>
      <form style={{ width: '100%' }}>
        <EvlBox textAlign="center" position="relative" justifyContent="center" alignItems="center">
          <EvlBox className={cover ? classes.cover : classes.noCover} onClick={handleCoverClick} />
          {!hideCaption && (
            <EvlTypography className={classes.title}>{translate('account.changePhoneNumber.code')}</EvlTypography>
          )}
          {!hideText && fullNumber && (
            <EvlTypography className={classes.subtitle}>
              {settings
                ? 'We have sent a 6-code verification code to your email'
                : translate('account.changePhoneNumber.codePanel.description')}
              <strong>{' ' + fullNumber}</strong>
            </EvlTypography>
          )}
          <EvlBox display="flex" justifyContent="center">
            {codeValues.map((codeValue: String, index: number) => (
              <React.Fragment>
                <TextField
                  id={`${index}`}
                  key={`${index}`}
                  value={codeValue}
                  inputRef={refs[index]}
                  onChange={handleCodeValueChange(index)}
                  onBlur={() => handleCodeValueBlur(index)}
                  onFocus={() => handleCodeValueFocus(index)}
                  onKeyDown={handleKeyDown(index)}
                  classes={{
                    root: classes.tokenInputField,
                  }}
                  autoComplete={index === 0 ? 'one-time-code' : ''}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={inputType === 'numbers' ? { pattern: '[0-9]*', inputMode: 'numeric' } : {}}
                  required
                />
              </React.Fragment>
            ))}
          </EvlBox>
          {showError && (
            <div className={classes.errorWrapper}>
              <EvlTypography variant="body2" className={classes.errorText}>
                {translate('codePanel.missingCode')}
              </EvlTypography>
            </div>
          )}
        </EvlBox>
      </form>
    </React.Fragment>
  );
};

export default VerificationToken;
