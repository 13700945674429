import React from 'react';
import { useField, FieldValidator } from 'formik';
import { InputAdornment, IconButton } from '@material-ui/core';
import { EvlTextBox } from 'evl-ui-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { baseTextField } from '../jss';
import classNames from 'classnames';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...baseTextField(theme).default,
      width: 'auto',
      minHeight: '48px',
      '& fieldset': {
        borderRadius: '8px !important',
        borderColor: '#ECEBED !important',
        backgroundColor: '#FFFFFF !important',
      },
      '&:hover fieldset': {
        borderColor: '#ECEBED !important',
      },
      '&.MuiInputLabel-shrink': {
        transform: 'translate(12px, -6px) scale(0.75)',
      },
      '&.Mui-error': {
        '& fieldset': {
          borderColor: '#B00020 !important',
        },
      },
    },
    inputRoot: baseTextField(theme).input,
    inputRootFocused: baseTextField(theme).inputFocused,
    inputLabelError: {
      '&.Mui-error': {
        color: '#B00020',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(12px, -6px) scale(0.75)',
        },
      },
    },
    inputLabelFocused: baseTextField(theme).inputLabelFocused,
    errorMessage: {
      fontFamily: theme.typography.fontFamily,
      color: '#B00020 !important',
      margin: theme.spacing(0),
    },
    startAdornment: {
      color: theme.palette.primary.main,
      //position: 'absolute',
      //top: '21px',
      //left: '12px',
      fontSize: theme.typography.fontSize,
    },
    inputAdornedStart: {
      paddingLeft: 0,

      // '& input': {
      //   paddingLeft: (props: { [key: string]: any }) =>
      //     props.inputAdornmentPaddingLeft || '52px',
      // },
    },
    capInput: {
      textTransform: 'uppercase',
    },
    lowercaseInput: {
      textTransform: 'lowercase',
    },
    autocomplete: {
      padding: '0 !important',
    },
    startAdornmentLabel: {
      width: theme.spacing(3),
      minWidth: 82,
      '& p': {
        color: theme.palette.primary.main,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        textTransform: 'capitalize',
      },
    },
    phoneNumberErrorMessage: {
      position: 'absolute',
      bottom: `-${theme.spacing(3.5)}px`,
      fontSize: '14.5px',
      left: 'calc(-20% - 92px)',
    },
    passwordIcon: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: theme.shape.borderRadius * 50,
      color: '#CDCDCD',
      '&:hover': {
        color: 'rgba(0, 0, 0, 0.54)',
        backgroundColor: 'transparent',
      },
    },
  }),
);

interface ValTextFieldProps {
  id?: string;
  name: string;
  noStartAdornment?: boolean;
  adornmentProps?: string;
  value?: any;
  label?: string;
  validate?: FieldValidator;
  hideLabel?: boolean;
  type?: string;
  inputAdornmentPaddingLeft?: string;
  hideBottomBorder?: boolean;
  isCapitalised?: boolean;
  isLowercased?: boolean;
  isPhoneField?: boolean;
  isPasswordField?: boolean;
  placeholder?: string;
  autoComplete?: string;
  [key: string]: any;
  changed?: (e: any) => void;
  formHelperTextComponent?: Function;
}

const ValTextField = ({
  id,
  name,
  value,
  label,
  noStartAdornment,
  adornmentProps,
  hideLabel,
  hideBottomBorder,
  type,
  inputAdornmentPaddingLeft,
  isCapitalised,
  isLowercased,
  isPhoneField,
  isPasswordField,
  placeholder,
  autoComplete,
  changed,
  ...props
}: ValTextFieldProps) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [field, meta] = useField({ name, validate: props.validate });

  const error: boolean = meta.touched === true && meta.error !== undefined && meta.error !== '';

  const endAdornmentForPassword = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        className={classes.passwordIcon}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  const formHelperTextProps = !props.formHelperTextComponent
    ? {
        id: 'textfield-error',
        error: true,
        classes: {
          root: classes.errorMessage,
          error: isPhoneField ? classes.phoneNumberErrorMessage : undefined,
        },
      }
    : { disabled: false };

  return (
    <>
      <EvlTextBox
        {...props}
        {...field}
        placeholder={placeholder ? placeholder : ''}
        id={id}
        type={isPasswordField ? (showPassword ? 'text' : 'password') : type}
        name={name}
        label={label}
        className={classNames((isCapitalised && classes.capInput) || '', isLowercased && classes.lowercaseInput)}
        helperText={!props.formHelperTextComponent && error && meta.error}
        autoComplete={autoComplete || props.autoComplete || 'off'}
        inputProps={{
          ...props.inputProps,
          autoComplete: autoComplete || 'off',
        }}
        InputProps={{
          ...props.InputProps,
          endAdornment: isPasswordField ? endAdornmentForPassword : null,
          disableUnderline: true,
          autoCapitalize: props.autoCapitalize || 'none',
          classes: {
            root: classes.root,
            input: classes.inputRoot,
            focused: classes.inputRootFocused,
          },
        }}
        InputLabelProps={{
          focused: true,
          classes: {
            root: classes.root,
            error: classes.inputLabelError,
            focused: classes.inputLabelFocused,
          },
        }}
        FormHelperTextProps={formHelperTextProps}
        onChange={e => {
          changed && changed(e);
          field.onChange(e);
        }}
      />
      {props.formHelperTextComponent && props.formHelperTextComponent(meta)}
    </>
  );
};

export default ValTextField;
