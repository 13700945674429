import React from 'react';

import _map from 'lodash/map';
import _isEqual from 'lodash/isEqual';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _intersectionWith from 'lodash/intersectionWith';

import { EvlGrid, EvlTypography } from 'evl-ui-components';
import { useFormatMessage } from 'evl-ui-lib';

import useStyles from './styles';

const PasswordValidationCheckList = React.memo((props: any) => {
  const classes = useStyles();
  const translate = useFormatMessage();

  const checkList = [
    { id: 1, text: translate('password.minlength.error') },
    { id: 2, text: translate('password.uppercase.error') },
    { id: 3, text: translate('password.lowercase.error') },
    { id: 4, text: translate('password.number.error') },
    { id: 5, text: translate('password.symbol.error') },
  ];

  const [inValidList, setInValidList] = React.useState<any | undefined>();

  React.useEffect(() => {
    const errors = props.error ? JSON.parse(props.error) : undefined;
    const result = _intersectionWith(checkList, errors, (v1: any, v2: string) => {
      return v1.text === v2;
    });
    setInValidList(result);
  }, [props.error]);

  const isTouched = !_isEqual(props.initialValue, props.value) || props.touched;

  if (_isEmpty(inValidList) || !isTouched) {
    return null;
  } else
    return (
      <EvlGrid container>
        <EvlGrid item xs={12}>
          <EvlTypography className={classes.prompt}>Password must:</EvlTypography>
        </EvlGrid>
        {_map(checkList, item => {
          const isCurrentItemInValid = _find(inValidList, ['id', item.id]);
          return (
            <EvlGrid key={item.id} container item xs={12} className={classes.validations}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill={isCurrentItemInValid ? '#636363' : '#65B365'}
                className={classes.promptIcon}
              >
                {isCurrentItemInValid ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 8C0 3.57333 3.57333 0 8 0C12.4267 0 16 3.57333 16 8C16 12.4267 12.4267 16 8 16C3.57333 16 0 12.4267 0 8ZM8 14.88C9.84889 14.88 11.5733 14.1689 12.8711 12.8711C14.1689 11.5733 14.88 9.83111 14.88 8C14.88 6.15111 14.1689 4.42667 12.8711 3.12889C11.5733 1.83111 9.83111 1.12 8 1.12C6.15111 1.12 4.42667 1.83111 3.12889 3.12889C1.83111 4.42667 1.12 6.16889 1.12 8C1.12 9.84889 1.83111 11.5733 3.12889 12.8711C4.42667 14.1689 6.16889 14.88 8 14.88ZM10.5955 4.62216C10.8088 4.40882 11.1644 4.40882 11.3777 4.62216C11.591 4.85327 11.591 5.19105 11.3777 5.42216L8.78216 7.99994L11.3599 10.5955C11.5733 10.8088 11.5733 11.1644 11.3599 11.3777C11.2533 11.4844 11.111 11.5377 10.9688 11.5377C10.8266 11.5377 10.6844 11.4844 10.5777 11.3777L7.99994 8.78216L5.40438 11.3599C5.29771 11.4666 5.15549 11.5199 5.01327 11.5199C4.87105 11.5199 4.72883 11.4666 4.62216 11.3599C4.40882 11.1466 4.40882 10.791 4.62216 10.5777L7.21771 7.99994L4.63994 5.40438C4.4266 5.19105 4.4266 4.83549 4.63994 4.62216C4.85327 4.40882 5.20883 4.40882 5.42216 4.62216L7.99994 7.21771L10.5955 4.62216Z"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 8C0 3.5831 3.5831 0 8 0C12.4169 0 16 3.5831 16 8C16 12.4169 12.4169 16 8 16C3.5831 16 0 12.4169 0 8ZM1.35211 8C1.35211 11.6732 4.32676 14.6479 8 14.6479C11.6732 14.6479 14.6479 11.6732 14.6479 8C14.6479 4.32676 11.6732 1.35211 8 1.35211C4.32676 1.35211 1.35211 4.32676 1.35211 8ZM6.71549 9.46479L10.907 5.27324C11.1775 5.00282 11.5831 5.00282 11.8535 5.27324C12.1239 5.52113 12.1239 5.9493 11.8535 6.21972L7.18873 10.8845C7.05352 11.0197 6.87324 11.0873 6.71549 11.0873C6.55775 11.0873 6.37746 11.0197 6.24225 10.8845L4.14648 8.78873C3.87606 8.54084 3.87606 8.11268 4.14648 7.84225C4.39437 7.57183 4.82253 7.57183 5.09296 7.84225L6.71549 9.46479Z"
                  />
                )}
              </svg>
              <EvlTypography className={isCurrentItemInValid ? classes.invalidText : classes.validText}>
                {item.text}
              </EvlTypography>
            </EvlGrid>
          );
        })}
      </EvlGrid>
    );
});

export default PasswordValidationCheckList;
