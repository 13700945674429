import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { Button } from 'evl-ui-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      textTransform: 'none',
      borderRadius: theme.shape.borderRadius * 2,
      height: `${theme.spacing(4)}px !important`,
      fontFamily: 'Lato',
      fontSize: '14px !important',
      fontWeight: 600,

      '&:hover': {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #062734',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      },
    },
    disabled: {
      opacity: '0.38 !important',
      fontFamily: 'Lato',
      fontSize: '14px !important',
      color: '#FFFFFF !important',

      '&:hover': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
    },
  }),
);

interface ButtonProps {
  buttonId: string;
  // buttonType: 'submit' | 'button';
  buttonType: 'button' | 'reset' | 'submit' | undefined;
  buttonLabel: string;
  buttonStyle?: string;
  className?: string;
  handleButtonClick?: any;
  buttonInProgress?: boolean;
  disableButton?: boolean;
  hideButton?: boolean;
  isFormButton?: boolean;
  href?: string;
}

interface ButtonBarProps {
  buttonList: Array<ButtonProps>;
  usePadding?: boolean;
  roundCorner?: boolean;
}

const ButtonBar = ({ buttonList, usePadding, roundCorner }: ButtonBarProps) => {
  const classes = useStyles({ usePadding, roundCorner });

  const renderButton = (buttonProps: ButtonProps) => {
    return (
      <Button
        key={buttonProps.buttonId}
        id={buttonProps.buttonId}
        type={buttonProps.buttonType}
        className={buttonProps.className}
        classes={{
          root: classes.button,
          disabled: classes.disabled,
        }}
        size="large"
        onClick={buttonProps.isFormButton ? undefined : buttonProps.handleButtonClick}
        fullWidth
        disabled={buttonProps.disableButton || buttonProps.buttonInProgress}
        href={buttonProps.href}
      >
        {buttonProps.buttonLabel}
      </Button>
    );
  };

  return <React.Fragment>{buttonList.map((buttonProps: ButtonProps) => renderButton(buttonProps))}</React.Fragment>;
};

export default ButtonBar;
