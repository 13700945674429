import Autocomplete from '@material-ui/lab/Autocomplete';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ValTextField } from '../components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      padding: '0 !important',
    },
    input: {
      padding: '12.5px 16px !important',
    },
    popper: {
      zIndex: 11000,
    },
    paper: {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.common.white,
    },
    popupIndicator: {
      color: theme.palette.primary.main,
    },
    clearIndicator: {
      color: theme.palette.primary.main,
    },
    noOptions: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
    },
  }),
);

interface CustomAutocompleteProps {
  inputValue: string;
  options: any;
  name: string;
  autoCapitalize: string;
  required?: boolean;
  label?: string;
  hideLabel?: boolean;
  hideBottomBorder?: boolean;
  noStartAdornment?: boolean;
  autoComplete?: string;
  type?: string;
  [key: string]: any;
  error?: boolean;
}

const CustomAutocomplete = ({
  inputValue,
  options,
  name,
  autoCapitalize,
  required,
  label,
  hideLabel,
  hideBottomBorder,
  noStartAdornment,
  autoComplete,
  type,
  error,
  ...props
}: CustomAutocompleteProps) => {
  const classes = useStyles();
  return (
    <Autocomplete
      {...props}
      inputValue={inputValue}
      options={options}
      classes={classes}
      renderInput={(params: any) => (
        <ValTextField
          {...params}
          name={name}
          value={inputValue}
          autoCapitalize={autoCapitalize}
          required={required}
          variant="filled"
          hideBottomBorder={hideBottomBorder ? hideBottomBorder : undefined}
          label={label ? label : undefined}
          hideLabel={hideLabel ? hideLabel : undefined}
          noStartAdornment={noStartAdornment}
          autoComplete={autoComplete || 'off'}
          type={type || 'text'}
          error={error}
        />
      )}
    />
  );
};

export default CustomAutocomplete;
