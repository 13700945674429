import * as React from 'react';
import { ButtonBar, ValTextField } from '../../components';
import { onEnterFocusNext } from '../../utils';
import { useStyles } from './normalLogin';
import { Formik, Form } from 'formik';
import { NewEmailValidations } from '../../validations';
import { useFormatMessage } from 'evl-ui-lib';
import { EvlBox, EvlContainer, EvlGrid, EvlTypography, EvlSnackbar } from 'evl-ui-components';
import { updateUserAttrs, getAttributeVerificationCode, emailAvailable } from '../../api/user';
import { Portal } from '@material-ui/core';

interface ChangeEmailPanelState {
  email: string;
}

interface ChangeEmailPanelProps {
  updateEmail: (email: string) => void;
  updatePanel: (panel: 'verification' | 'confirmation' | 'changePhoneNumber' | undefined) => void;
  existingEmail?: string;
  settings?: boolean;
}

const ChangeEmailPanel = ({ updateEmail, updatePanel, existingEmail, settings }: ChangeEmailPanelProps) => {
  const classes = useStyles();
  const translate = useFormatMessage();

  const initialState: ChangeEmailPanelState = {
    email: '',
  };

  const [state, setState] = React.useState<ChangeEmailPanelState>(initialState);
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendCodeClick = async (values: any) => {
    setState(values);
    if (existingEmail?.toUpperCase() === values.email.toUpperCase()) {
      setMessage('Same as existing email');
      setOpen(true);
      return false;
    }
    try {
      await emailAvailable(values.email);
    } catch (err) {
      setMessage(translate('verifyEmail.used'));
      setOpen(true);
      return false;
    }
    try {
      const updatedEmail: any = await updateUserAttrs({
        userAttributes: [
          {
            Name: 'email',
            Value: values.email,
          },
        ],
      });
      if (!updatedEmail.CodeDeliveryDetailsList) {
        await getAttributeVerificationCode({ attributeName: 'email' });
      }
      updateEmail(values.email);
      updatePanel('verification');
      return true;
    } catch (err) {
      throw err;
    }
  };

  const renderEmailField = (error: boolean) => (
    <ValTextField
      id="textfield-verify-email"
      name="email"
      value={state.email}
      label={settings ? 'New email*' : 'Email'}
      autoCapitalize="off"
      onKeyDown={onEnterFocusNext}
      error={error}
    />
  );

  const renderEmailPanel = () => (
    <React.Fragment>
      <Portal>
        <div className={classes.snackbar}>
          <EvlSnackbar open={open} success={false} message={message} handleClose={handleClose} duration={5000} />
        </div>
      </Portal>
      <EvlBox pt={1.4} className={classes.authContainer}>
        <EvlContainer maxWidth="sm">
          <EvlGrid container justify="center" alignItems="center" spacing={3}>
            {!settings && (
              <EvlGrid item xs={12}>
                <EvlTypography color="textSecondary" variant="body2" align="center">
                  {translate('verifyEmail.subtitle')}
                </EvlTypography>
              </EvlGrid>
            )}
            <EvlGrid item xs={12}>
              <Formik
                validateOnBlur={true}
                validateOnChange={true}
                validateOnMount={true}
                validationSchema={NewEmailValidations(translate)}
                onSubmit={handleSendCodeClick}
                initialValues={initialState}
              >
                {({ errors, touched }) => (
                  <Form>
                    <EvlGrid container justify="center" alignItems="center" spacing={3}>
                      <EvlGrid item xs={12}>
                        <EvlGrid container justify="center" alignItems="center" spacing={2}>
                          <EvlGrid item xs={12} className={classes.username}>
                            {renderEmailField(errors.email !== undefined && touched.email === true)}
                          </EvlGrid>
                        </EvlGrid>
                      </EvlGrid>
                      <EvlGrid item xs={12} className={classes.verifyButtons}>
                        <ButtonBar
                          buttonList={[
                            {
                              buttonId: 'verify-submit-button',
                              buttonType: 'submit' as 'submit',
                              buttonStyle: 'primary',
                              buttonLabel: translate('button.sendCode'),
                              isFormButton: true,
                            },
                            {
                              buttonId: 'verify-cancel-button',
                              className: classes.cancelButton,
                              buttonType: 'button',
                              buttonLabel: translate('button.cancel'),
                              isFormButton: false,
                              href: settings ? '/settings?panel=personalDetails' : '/login?panel=normalLoginPanel',
                            },
                          ]}
                        />
                      </EvlGrid>
                    </EvlGrid>
                  </Form>
                )}
              </Formik>
            </EvlGrid>
          </EvlGrid>
        </EvlContainer>
      </EvlBox>
    </React.Fragment>
  );

  return renderEmailPanel();
};

export default ChangeEmailPanel;
