import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    failureContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: (props: { [key: string]: any }) => (props.removePadding ? 0 : `${theme.spacing(2)}px`),
      width: '100%',
      textAlign: 'center',
      fontSize: 15,
      fontStyle: 'normal',
      fontFamily: theme.typography.fontFamily,
      minHeight: theme.spacing(5),
      alignContent: 'center',
      color: '#d23228',
      justifyContent: 'flex-start',
    },
  }),
);

interface ErrorMessageProps {
  message: string;
  removePadding?: boolean;
}

const ErrorMessage = ({ message, removePadding }: ErrorMessageProps) => {
  const classes = useStyles({ removePadding });

  return (
    <div id="panel-failure-message" className={classes.failureContainer}>
      {message}
    </div>
  );
};

export default ErrorMessage;
